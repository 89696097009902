<template>
    <div class="d-flex">
        <div class="armory-inputsearch-container">
            <input @keyup="emitTypeValue" v-model="searchTerm" type="text" id="searchTermModsInput" class="field-input armory-search-input"
                   placeholder="Search mods...">
            <div @click="clearAndEmmit" class="search-clear-btn" id="cs-btn"><i class="fa-solid fa-octagon-xmark"></i></div>
        </div>
        <div class="armory-filter-mods-container">
            <select class="form-select" v-model="tierFilter">
                <option value="0">All</option>
                <option value="1">Tier 1</option>
                <option value="2">Tier 2</option>
                <option value="3">Tier 3</option>
            </select>
        </div>
    </div>
</template>
<script>

import store from '../store'
import eventBus from "../eventBus";

export default {
    data: function () {
        return {
            tierFilter: 0,
            modType: 'weapon',
            searchTerm: ''
        }
    },
    watch: {
        tierFilter() {
            // console.log('changing tier value on the list');
            // console.log('the value is now: ', this.tierFilter);
            eventBus.$emit('armory:ModFilter', this.tierFilter);
            this.clearAndEmmit();
        }
        // modType() {
        //     eventBus.$emit('armory:ModFilter', this.modType);
        // }
    },
    mounted() {
        const self = this;
        // console.log('Input Search component mounted.')
        eventBus.$on('armory:ChangeTierSelect', function (modTier) {
            self.tierFilter = modTier;
        });
        eventBus.$on('armory:ClearModSearch', function () {
            self.clearAndEmmit();
        });
    },
    methods: {
        clearSearch() {
            $('#searchTermModsInput', this.$el).val('');
            $('#cs-btn', this.$el).removeClass('show');
            this.searchTerm = '';
        },
        clearAndEmmit() {
            this.searchTerm = '';
            $('#cs-btn', this.$el).removeClass('show');
            eventBus.$emit('armory:SearchMods', '');
            this.searchTerm = '';
        },
        emitTypeValue() {
            let typedValue = this.searchTerm.toLowerCase();
            if (typedValue.length > 0) {
                $('#cs-btn', this.$el).addClass('show');
            } else {
                $('#cs-btn', this.$el).removeClass('show');
            }
            eventBus.$emit('armory:SearchMods', typedValue);
        }
    }
}
</script>
