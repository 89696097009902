<template>
    <!-- ARMOR SETS -->
    <div class="explorer-section" :id="segmentId">
        <div class="segment-header-container explorer">
            <div class="segment-header">
                <div class="explorer-controls">
                    <div class="explorer-headline">
                        <div class="explorer-search-container">
                            <input @keyup="emitTypeValue" v-model="searchTerm" type="text" id="searchSetInput"
                                   class="field-input explorer-search-input"
                                   placeholder="Search...">
                            <div @click="clearAndEmmit" class="search-clear-btn explorer" id="cs-btn-sets"><i
                                class="fa-solid fa-octagon-xmark"></i>
                            </div>
                        </div>
                        <div class="explorer-rarity-container">
                            <select class="form-select" v-model="rarityFilter">
                                <option value="0">All</option>
                                <option value="1">Common</option>
                                <option value="2">Unusual</option>
                                <option value="3">Rare</option>
                                <option value="4">Epic</option>
                                <option value="5">Legendary</option>
                            </select>
                        </div>
                    </div>
                    <div @click="currentNext('prev')" class="std-btn sm compact"
                         :style="setNoSlides > 1 ? '' : 'pointer-events: none; opacity: 0.5'">
                        <i class="fa-solid fa-angle-left"></i>
                    </div>
                    <div class="explorer-right-controls">
                        <div @click="currentNext('next')" class="std-btn sm compact"
                             :style="setNoSlides > 1 ? '' : 'pointer-events: none; opacity: 0.5'"><i
                            class="fa-solid fa-angle-right"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="explorer-sectionals">
            <div class="explorer-carousel">
                <div v-for="(set, index) in sets" class="set-container scroll-section" :data-id="index" :data-name="set.name">
                    <div class="explorer-stats">
                        <div class="explorer-stats-details">
                            <div class="explorer-label-headline mb-3 explorer-details-header">
                                <div class="explorer-sets-header">
                                    Armor Sets
                                </div>
                                <div class="explorer-sets-pattern">
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                    <div class="slant"></div>
                                </div>
                            </div>
                            <div class="explorer-label-headline">
                                <div class="text-label">SET</div>
                                <div class="connector-line">
                                    <div class="line"></div>
                                </div>
                            </div>
                            <div class="explorer-label-title">
                                <div class="text-label">{{ set.name }}</div>
                            </div>
                            <div class="spacer"></div>
                            <div class="explorer-label-headline">
                                <div class="text-label">CLASS</div>
                                <div class="connector-line">
                                    <div class="line"></div>
                                </div>
                            </div>
                            <div class="explorer-label-default">
                                <div class="text-label">{{ set.class }}</div>
                            </div>
                            <div class="spacer"></div>
                            <div class="explorer-label-headline">
                                <div class="text-label">RARITY</div>
                                <div class="connector-line">
                                    <div class="line"></div>
                                </div>
                            </div>
                            <div class="explorer-label-default">
                                <div :class="'text-label '+set.rarity">{{ set.rarity }}</div>
                            </div>
                            <div class="spacer"></div>
                            <div class="explorer-label-headline">
                                <div class="text-label">SET BONUS</div>
                                <div class="connector-line">
                                    <div class="line"></div>
                                </div>
                            </div>
                            <div class="explorer-label-default">
                                <div class="text-label sm bonus" v-html="set.setbonus"></div>
                            </div>
                            <div class="spacer"></div>
                            <div class="explorer-label-headline">
                                <div class="text-label">SET ITEMS</div>
                                <div class="connector-line">
                                    <div class="line"></div>
                                </div>
                            </div>
                            <div class="armory-pieces">
                                <div class="armory-item explorer-armor headgear">
                                    <div class="item-visual ">
                                        <div :class="'item-visual-bg'"></div>
                                        <div class="item-visual-bg hovershadow"></div>
                                        <div class="content">
                                            <div :class="'visual'"
                                                 :data-img="getArmorPieceImageURL(set.items[0], set.rarity)"></div>
                                            <div :class="'visual sharpen'"
                                                 :data-img="getArmorPieceImageURL(set.items[0], set.rarity)"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="armory-item explorer-armor upper">
                                    <div class="item-visual ">
                                        <div :class="'item-visual-bg'"></div>
                                        <div class="item-visual-bg hovershadow"></div>
                                        <div class="content">
                                            <div :class="'visual'"
                                                 :data-img="getArmorPieceImageURL(set.items[1], set.rarity)"></div>
                                            <div :class="'visual sharpen'"
                                                 :data-img="getArmorPieceImageURL(set.items[1], set.rarity)"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="armory-item explorer-armor lower">
                                    <div class="item-visual ">
                                        <div :class="'item-visual-bg'"></div>
                                        <div class="item-visual-bg hovershadow"></div>
                                        <div class="content">
                                            <div :class="'visual'"
                                                 :data-img="getArmorPieceImageURL(set.items[2], set.rarity)"></div>
                                            <div :class="'visual sharpen'"
                                                 :data-img="getArmorPieceImageURL(set.items[2], set.rarity)"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="armory-item explorer-armor gloves">
                                    <div class="item-visual ">
                                        <div :class="'item-visual-bg'"></div>
                                        <div class="item-visual-bg hovershadow"></div>
                                        <div class="content">
                                            <div :class="'visual'"
                                                 :data-img="getArmorPieceImageURL(set.items[3], set.rarity)"></div>
                                            <div :class="'visual sharpen'"
                                                 :data-img="getArmorPieceImageURL(set.items[3], set.rarity)"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="armory-item explorer-armor footgear">
                                    <div class="item-visual ">
                                        <div :class="'item-visual-bg'"></div>
                                        <div class="item-visual-bg hovershadow"></div>
                                        <div class="content">
                                            <div :class="'visual'"
                                                 :data-img="getArmorPieceImageURL(set.items[4], set.rarity)"></div>
                                            <div :class="'visual sharpen'"
                                                 :data-img="getArmorPieceImageURL(set.items[4], set.rarity)"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="explorer-figure" :data-default-image="set.slug">
                            <div class="character-visual">
                                <div class="content" :data-img="getSetImageOnly( set.slug + '-set.png')"></div>
                                <div class="content sharpen" :data-img="getSetImageOnly( set.slug + '-set.png')"></div>
                            </div>
                        </div>
                        <div class="explorer-stats-controls">
                            <div class="explorer-stats-controls-side">
                                <div @click="changeActiveArmorAngle(set.slug, 1, 0, $event)" class="stat-action">
                                    <div class="content"><img
                                        src="https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/set-angle-icon-01.png"/>
                                    </div>
                                </div>
                                <div @click="changeActiveArmorAngle(set.slug, 1, 1, $event)" class="stat-action">
                                    <div class="content"><img
                                        src="https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/set-angle-icon-02.png"/>
                                    </div>
                                </div>
                                <div @click="changeActiveArmorAngle(set.slug, 1, 2, $event)" class="stat-action">
                                    <div class="content"><img
                                        src="https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/set-angle-icon-03.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>

</style>
<script>

import store from '../store'
import eventBus from "../eventBus";
import setsData from '../sets.json';
import gear from "../gear.json";

export default {
    components: {},
    props: ['segmentId'],
    directives: {
        // directive definition
    },
    data: function () {
        return {
            searchTerm: '',
            setIndex: 0,
            setNoSlides: 0,
            sets: null,
            rarityFilter: 0,
            filteredArmorSets: null,
            settings: {
                arrows: false,
                dots: false,
                // "infinite": true,
                "speed": 500,
                "cssEase": "cubic-bezier(1.000, 0.000, 0.000, 1.000)",
                touchMove: false,
                lazyLoad: "ondemand",
            }
        }
    },
    computed: {},
    watch: {
        rarityFilter() {
            this.clearAndEmmit();
            eventBus.$emit('explorer:SetsTypeFilter', this.rarityFilter);
        }
    },
    created() {
        //
        const _this = this;
    },
    mounted() {
        const _this = this;
        // console.log('Armor Sets component mounted.')
        // get sets Data
        this.sets = this.filterSets();
        this.filteredArmorSets = [...this.sets];
        this.setIndex = 0;
        this.setNoSlides = this.sets.length;
        this.refreshFirstSlideAssets();
        eventBus.$on('explorer:SetsTypeFilter', function (gearRarity) {
            _this.sets = _this.filterSets(gearRarity);
        });
    },
    methods: {
        filterSets(gearRarity = 0) {
            let dt, ds, dn = [];
            let activeData = setsData;

            if (parseInt(gearRarity) === 0) {
                dn = activeData;
            } else {
                let rarityString = '';
                switch (parseInt(gearRarity)) {
                    case 1:
                        rarityString = 'common';
                        break;
                    case 2:
                        rarityString = 'unusual';
                        break;
                    case 3:
                        rarityString = 'rare';
                        break;
                    case 4:
                        rarityString = 'epic';
                        break;
                    case 5:
                        rarityString = 'legendary';
                        break;
                }

                dt = activeData.filter(x => x.rarity === rarityString)
                dn = dt;
            }
            this.setNoSlides = dn.length;
            return dn;
        },
        hasClass(el, className) {
            return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
        },
        addClass(el, className) {
            if (el.classList) el.classList.add(className);
            else if (!this.hasClass(el, className)) el.className += ' ' + className;
        },
        removeClass(el, className) {
            if (el.classList) el.classList.remove(className);
            else el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
        },
        changeActiveArmorAngle(slug, elId, angleID, event) {
            const self = this;
            let statAction = event.currentTarget;
            let statActionParent = event.currentTarget.parentNode;
            let statActions = statActionParent.querySelectorAll('.stat-action');
            _.forEach(statActions, function (el, index) {
                self.removeClass(el, 'active');
            });
            self.addClass(statAction, 'active');
            // this.removeClass(statActions, 'active');
            // console.log(statActions);
            let parentNode = event.currentTarget.parentNode.parentNode.parentNode;
            let exploreFigure = parentNode.querySelector('.explorer-figure');
            let defaultImage = exploreFigure.getAttribute('data-default-image');
            let setSlug = slug;
            //
            let characterVisual = exploreFigure.querySelector('.character-visual').querySelector('.content');
            let characterVisualSharpen = exploreFigure.querySelector('.character-visual').querySelector('.content.sharpen');

            if (angleID == 0) {
                characterVisual.style = this.getSetImageBackground(setSlug + '-set.png');
                characterVisualSharpen.style = this.getSetImageBackground(setSlug + '-set.png');
            }
            if (angleID == 1) {
                characterVisual.style = this.getSetImageBackground(setSlug + '-set1.png');
                characterVisualSharpen.style = this.getSetImageBackground(setSlug + '-set1.png');
            }
            if (angleID == 2) {
                characterVisual.style = this.getSetImageBackground(setSlug + '-set2.png');
                characterVisualSharpen.style = this.getSetImageBackground(setSlug + '-set2.png');
            }
        },
        beforeContentSlideChanges(oldIndex, newIndex) {
            let $slick = this.$refs.armorSlick;
            let previousSlide = $($slick.$el).find('.slick-slide').eq(newIndex);
        },
        searchArmorSets(value) {
            const _this = this;

            this.sets = this.filteredArmorSets;
            let searchFiltered = [];
            let rarity = 'all'
            switch (this.rarityFilter) {
                case "0":
                    rarity = 'all';
                    break;
                case "1":
                    rarity = 'common';
                    break;
                case "2":
                    rarity = 'unusual';
                    break;
                case "3":
                    rarity = 'rare';
                    break;
                case "4":
                    rarity = 'epic';
                    break;
                case "5":
                    rarity = 'legendary';
                    break;
            }

            if (rarity === 'all') {
                searchFiltered = this.sets.filter(function (x) {
                    // x => (x.name).toLowerCase().includes(value)
                    let sourceName = x.name;
                    let sourceTargetString = sourceName.toLowerCase();
                    return sourceTargetString.includes(value);
                });
            } else {
                searchFiltered = this.sets.filter(function (x) {
                    // x => (x.name).toLowerCase().includes(value)
                    let sourceName = x.name;
                    let sourceTargetString = sourceName.toLowerCase();
                    return sourceTargetString.includes(value) && x.rarity === rarity;
                });
            }
            this.setIndex = 0;
            this.setNoSlides = searchFiltered.length;
            this.sets = searchFiltered;
            this.refreshFirstSlideAssets();
        },
        navigateSetSlide() {
            let index = this.setIndex;
            let $targetSlide = $('.set-container.scroll-section', $('#' + this.segmentId)).eq(index);
            let $setCharacterImage = $('.explorer-figure', $targetSlide);
            let $contentImage = $('.content', $setCharacterImage).eq(0);
            let $contentImageSharpen = $('.content', $setCharacterImage).eq(1);
            let contentImageURL = $contentImage.attr('data-img');

            $contentImage.css('background-image', 'url("' + contentImageURL + '")');
            $contentImageSharpen.css('background-image', 'url("' + contentImageURL + '")');
            let $armorPiece = $('.armory-pieces .armory-item', $targetSlide);
            $armorPiece.each(function (i, el) {
                $('.content .visual', $(el)).css('background-image', 'url(' + $('.content .visual', $(el)).attr('data-img') + ')');
            });
            gsap.to($('.explorer-carousel', $('#' + this.segmentId)), {xPercent: -1 * index * 100, duration: 0.5, ease: "expo.inOut"})
        },
        refreshFirstSlideAssets(index = 0) {
            const _this = this;
            this.setIndex = 0;
            gsap.set($('.explorer-carousel', $('#' + _this.segmentId)), {xPercent: 0});
            this.$nextTick(function () {
                let $targetSlide = $('.set-container.scroll-section', $('#' + _this.segmentId)).eq(0);
                let $setCharacterImage = $('.explorer-figure', $targetSlide);
                let $contentImage = $('.content', $setCharacterImage).eq(0);
                let $contentImageSharpen = $('.content', $setCharacterImage).eq(1);
                let contentImageURL = $contentImage.attr('data-img');

                let $armorPiece = $('.armory-pieces .armory-item', $targetSlide);
                $armorPiece.each(function (i, el) {
                    $('.content .visual', $(el)).css('background-image', 'url(' + $('.content .visual', $(el)).attr('data-img') + ')');
                });

                $contentImage.css('background-image', 'url("' + contentImageURL + '")');
                $contentImageSharpen.css('background-image', 'url("' + contentImageURL + '")');
            })
        },
        currentNext(direction) {
            if (direction === 'next') {
                if (this.setIndex < this.setNoSlides - 1) {
                    this.setIndex++;
                    this.navigateSetSlide();
                }
            }
            if (direction === 'prev') {
                if (this.setIndex <= 0) {
                    // do nothing
                } else {
                    this.setIndex--;
                    this.navigateSetSlide();
                }
            }
            return false;
        },
        getSetImageBackground(filename) {
            return 'background-image: url("https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/sets/' + filename + '")';
        },
        getSetImageOnly(filename) {
            return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/sets/' + filename;
        },
        getArmorPieceImageURL(id, rarity) {
            let d = gear.filter(x => x.id == id);
            return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/armor/' + d[0].group + '/' + rarity + '/' + d[0].filename;
        },
        resetSetStates() {
            const _this = this;
            let $slides = $('.set-container.scroll-section', $('#' + this.segmentId));
            this.setNoSlides = 0;
            this.setIndex = 0;
            $slides.each(function (i, el) {
                if ($(el).css('display') === 'none') {
                    // it's hidden don't count it
                } else {
                    _this.setNoSlides++;
                }
            })
        },
        resetAllSetsVisibility() {
            const _this = this;
            let $slides = $('.set-container.scroll-section', $('#' + this.segmentId));
            $slides.each(function (i, el) {
                $(el).css('display', 'flex')
            })
        },
        clearSearch() {
            const _this = this;
            $('#cs-btn-sets', this.$el).removeClass('show');
            $('#searchSetInput', this.$el).val('');
            _this.searchTerm = '';
            this.searchArmorSets('');
        },
        clearAndEmmit() {
            this.clearSearch();
        },
        emitTypeValue() {
            let typedValue = this.searchTerm.toLowerCase();
            if (typedValue.length > 0) {
                $('#cs-btn-sets', this.$el).addClass('show');
            } else {
                $('#cs-btn-sets', this.$el).removeClass('show');
            }
            this.searchArmorSets(typedValue);
        },
    }
}
</script>
