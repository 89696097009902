import Vue from "vue";
// import VueCarousel from 'vue-carousel';

window.Vue = Vue;
window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // window.Popper = require('popper.js').default;
    window.Popper = require('@popperjs/core');
    window.$ = window.jQuery = require('jquery');
    window.gsap = require('gsap').default;
    window.easydropdown = require('./easydropdown').default;
    window.Tipped = require('@staaky/tipped');
    require('overlayscrollbars');
    require('select2');
    require('bootstrap');
    window.Flip = require('gsap/Flip').default;
    gsap.registerPlugin(Flip);
} catch (e) {
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Register Vue Components
Vue.component('build-library', require('./components/BuildLibraryComponent.vue').default);
Vue.component('profile-info', require('./components/ProfileInfoComponent.vue').default);
Vue.component('search-input-component', require('./components/SearchInputComponent.vue').default);
Vue.component('search-input-mods-component', require('./components/SearchInputModsComponent.vue').default);
Vue.component('armory-component', require('./components/ArmoryComponent.vue').default);
Vue.component('mods-component', require('./components/ModsComponent.vue').default);
Vue.component('explorer-component', require('./components/ExplorerComponent').default);
Vue.component('armorsets-component', require('./components/ArmorSetsComponent').default);
Vue.component('legendaryweapons-component', require('./components/LegendaryWeaponsComponent').default);
Vue.component('podcast-component', require('./components/PodcastComponent').default);
Vue.mixin(require('./mixins/asset'));

import store from './store';

// Initialize Vue
const app = new Vue({
    el: '#app',
    data: {
        store: store
    }
});

// Vue.use(VueCarousel);
//
// window.app = app;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
