<template>
    <!-- LEGENDARY WEAPON SETS -->
    <div class="explorer-section" :id="segmentId">
        <div class="segment-header-container explorer">
            <div class="segment-header">
                <div class="explorer-controls">
                    <div class="explorer-headline">
                        <div class="explorer-search-container">
                            <input @keyup="emitTypeValue" v-model="searchTerm" type="text" id="searchWeaponInput"
                                   class="field-input explorer-search-input"
                                   placeholder="Search...">
                            <div @click="clearAndEmmit" class="search-clear-btn explorer" id="cs-btn-weapons"><i
                                class="fa-solid fa-octagon-xmark"></i>
                            </div>
                        </div>
                        <div class="explorer-rarity-container weapons">
                            <select class="form-select" v-model="weaponTypeFilter">
                                <option value="0">All</option>
                                <option value="1">Assault Rifles</option>
                                <option value="2">Double Guns</option>
                                <option value="3">Light Machineguns</option>
                                <option value="4">Submachine Guns</option>
                                <option value="5">Automatic Shotguns</option>
                                <option value="6">Pump-Action Shotguns</option>
                                <option value="7">Automatic Sniper Rifles</option>
                                <option value="8">Bolt-Action Rifles</option>
                                <option value="9">Rifles</option>
                                <option value="10">Pistols</option>
                                <option value="11">Revolver</option>
                            </select>
                        </div>
                    </div>
                    <div @click="currentNext('prev')" class="std-btn sm compact"
                         :style="setNoSlides > 1 ? '' : 'pointer-events: none; opacity: 0.5'">
                        <i class="fa-solid fa-angle-left"></i>
                    </div>
                    <div class="explorer-right-controls">
                        <div @click="currentNext('next')" class="std-btn sm compact"
                             :style="setNoSlides > 1 ? '' : 'pointer-events: none; opacity: 0.5'"><i
                            class="fa-solid fa-angle-right"></i></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="explorer-sectionals">
            <div class="explorer-carousel">
                <div v-for="(set, index) in sets" class="set-container scroll-section weapons" :data-id="index" :data-name="set.title">
                    <!--                    <div class="weapon-explorer-scrollable">-->
                    <!--                    <overlay-scrollbars v-on:updated="testEvent" :options="{ className: 'os-theme-light', scrollbars: { autoHide: 'scroll' } }">-->
                    <div class="weapon-stats">
                        <div class="weapon-visual-with-stats">
                            <div class="weapon-visual">
                                <div class="weapon-header">
                                    <div class="weapon-info-headline">
                                        <div class="weapon-header-section-title"><span>Legendary</span><span>Weapons</span></div>
                                        <div class="explorer-sets-pattern">
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                            <div class="slant"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="weapon-lines ">
                                    <div class="line"></div>
                                </div>
                                <div class="weapon-lines ">
                                    <div class="line"></div>
                                </div>
                                <div class="weapon-lines ">
                                    <div class="line"></div>
                                </div>
                                <div class="weapon-lines ">
                                    <div class="line"></div>
                                </div>
                                <div class="weapon-lines ">
                                    <div class="line"></div>
                                </div>
                                <div class="weapon-lines ">
                                    <div class="line"></div>
                                </div>
                                <div class="weapon-lines ">
                                    <div class="line"></div>
                                </div>
                                <div class="weapon-lines ">
                                    <div class="line"></div>
                                </div>
                                <div class="weapon-lines ">
                                    <div class="line"></div>
                                </div>
                                <div :class="'content' + setWeaponClassSizing(set.group)"
                                     :data-img="getSetImageOnly( set.filename, set.group)"></div>
                                <div :class="'content sharpen' + setWeaponClassSizing(set.group)"
                                     :data-img="getSetImageOnly( set.filename, set.group)"></div>
                            </div>
                            <div class="variant-stats-container horizontal">
                                <div class="weapon-variant-stats">
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.clip_size }}</div>
                                        <div class="weapon-variant-label">Clip Size</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.rpm }}</div>
                                        <div class="weapon-variant-label">RPM</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{
                                                Math.round(99403 / set.variant_data.multiplier / (set.variant_data.rpm / 60))
                                            }}
                                        </div>
                                        <div class="weapon-variant-label">DMG</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.reload }}</div>
                                        <div class="weapon-variant-label">Reload</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.crit_mult }}</div>
                                        <div class="weapon-variant-label">Crit. Mult.</div>
                                    </div>
                                </div>
                                <div class="weapon-variant-stats accuracy">
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.accuracy }}</div>
                                        <div class="weapon-variant-label">Accuracy</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.stability }}</div>
                                        <div class="weapon-variant-label">stability</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.range }}</div>
                                        <div class="weapon-variant-label">range</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.mode }}</div>
                                        <div class="weapon-variant-label">mode</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="weapon-info">
                            <div class="weapon-details">
                                <div class="weapon-basic">
                                    <div class="weapon-title"><span>{{ set.title }}</span>
                                        <div class="weapon-firepower">99.4k</div>
                                    </div>
                                    <div class="weapon-type">{{ set.group_title }}</div>
                                    <div class="weapon-variant">{{ set.variant_data.name }} VAR.</div>
                                </div>
                                <div class="weapon-attributes">
                                    <ul>
                                        <li v-for="(attr, index) in set.attributes_info">{{ attr.name }}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="variant-stats-container">
                                <div class="weapon-variant-stats">
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.clip_size }}</div>
                                        <div class="weapon-variant-label">Clip Size</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.rpm }}</div>
                                        <div class="weapon-variant-label">RPM</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{
                                                Math.round(99403 / set.variant_data.multiplier / (set.variant_data.rpm / 60))
                                            }}
                                        </div>
                                        <div class="weapon-variant-label">DMG</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.reload }}</div>
                                        <div class="weapon-variant-label">Reload</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.crit_mult }}</div>
                                        <div class="weapon-variant-label">Crit. Mult.</div>
                                    </div>
                                </div>
                                <div class="weapon-variant-stats accuracy">
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.accuracy }}</div>
                                        <div class="weapon-variant-label">Accuracy</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.stability }}</div>
                                        <div class="weapon-variant-label">stability</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.range }}</div>
                                        <div class="weapon-variant-label">range</div>
                                    </div>
                                    <div class="weapon-variant-stat">
                                        <div class="weapon-variant-value">{{ set.variant_data.mode }}</div>
                                        <div class="weapon-variant-label">mode</div>
                                    </div>
                                </div>
                            </div>
                            <div class="weapon-stats-info">
                                <div class="weapon-mods">
                                    <div class="weapon-mod-item">
                                        <div class="weapon-mod-icon" :data-id="'mod-id-' + set.mods[0]">
                                            <div class="content">
                                                <div class="mod-tier">{{ convertTierToRoman(set.mod_info[0].tier) }}</div>
                                                <div class="visual" :style="getWeaponModImageTag(set.mod_info[0].image)"></div>
                                                <div class="visual sharpen" :style="getWeaponModImageTag(set.mod_info[0].image)"></div>
                                            </div>
                                            <div class="mod-item-tooltip" :id="'weapon-mod-tooltip-'+set.mods[0]">
                                                <div class="mod-item-tooltip-header">{{ set.mod_info[0].name }}</div>
                                                <div class="mod-item-tooltip-body"><span v-html="set.mod_info[0].description"></span></div>
                                            </div>
                                        </div>
                                        <div class="weapon-mod-information">
                                            <div class="weapon-mod-title">{{ set.mod_info[0].name }}</div>
                                            <div class="weapon-mod-description"
                                                 v-html="set.mod_info[0].description"></div>
                                        </div>
                                    </div>
                                    <div class="weapon-mod-item">
                                        <div class="weapon-mod-icon" :data-id="'mod-id-' + set.mods[1]">
                                            <div class="content">
                                                <div class="mod-tier">{{ convertTierToRoman(set.mod_info[1].tier) }}</div>
                                                <div class="visual" :style="getWeaponModImageTag(set.mod_info[1].image)"></div>
                                                <div class="visual sharpen" :style="getWeaponModImageTag(set.mod_info[1].image)"></div>
                                            </div>
                                            <div class="mod-item-tooltip" :id="'weapon-mod-tooltip-'+set.mods[1]">
                                                <div class="mod-item-tooltip-header">{{ set.mod_info[1].name }}</div>
                                                <div class="mod-item-tooltip-body"><span v-html="set.mod_info[1].description"></span></div>
                                            </div>
                                        </div>
                                        <div class="weapon-mod-information">
                                            <div class="weapon-mod-title">{{ set.mod_info[1].name }}</div>
                                            <div class="weapon-mod-description"
                                                 v-html="set.mod_info[1].description"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--                    </overlay-scrollbars>-->
                    <!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>
<style>

</style>
<script>

import store from '../store'
import eventBus from "../eventBus";
import setsData from '../weapons.json';
import weaponTypes from "../weapon-types.json";
import variants from "../variants.json";
import mods from "../mods.json";
import weaponAttributes from "../attributes.json";
import {OverlayScrollbarsComponent} from 'overlayscrollbars-vue';


export default {
    components: {
        'overlay-scrollbars': OverlayScrollbarsComponent
    },
    props: ['segmentId'],
    directives: {
        // directive definition
    },
    data: function () {
        return {
            searchTerm: '',
            setIndex: 0,
            setNoSlides: 0,
            sets: null,
            weaponTypeFilter: 0,
            filteredSet: null,
            settings: {
                arrows: false,
                dots: false,
                // "infinite": true,
                "speed": 500,
                "cssEase": "cubic-bezier(1.000, 0.000, 0.000, 1.000)",
                touchMove: false,
                lazyLoad: "ondemand",
            }
        }
    },
    computed: {},
    watch: {
        weaponTypeFilter() {
            this.clearAndEmmit();
            // console.log(this.weaponTypeFilter);
            eventBus.$emit('explorer:WeaponsTypeFilter', this.weaponTypeFilter);
        }
    },
    created() {
        const _self = this;
        //$('.weapon-mod-item', this.$el)
        this.$nextTick(function () {
            // register overlay scrollbar on the elements
        });
    },
    beforeUpdate() {
        let _self = this;
        $('.weapon-mod-item', this.$el).each(function (i, el) {
            Tipped.remove($(el));
        });

        // _self.destroyScrollersOnContent();
        // $('.weapon-explorer-scrollable', this.$el).each(function (i, el) {
        //     OverlayScrollbars(el).destroy();
        //     console.log(el);
        // });

        // destroy overlay scrollbars
        // destroy();

    },
    updated() {
        let _self = this;

        this.$nextTick(function () {
            // register overlay scrollbar on the elements
            // OverlayScrollbars(document.querySelectorAll(".weapon-explorer-scrollable"), {
            //     className: "os-theme-light",
            //     scrollbars: {
            //         // autoHide: 'scroll'
            //     }
            // });

            // register overlay scrollbar on the elements
            // OverlayScrollbars(_self.$el.querySelectorAll(".weapon-explorer-scrollable"), {
            //     className: "os-theme-light",
            //     scrollbars: {
            //         // autoHide: 'scroll'
            //     }
            // });

            // OverlayScrollbars(_self.$el.querySelectorAll(".weapon-explorer-scrollable"), {
            //     className: "os-theme-light",
            //     scrollbars: {
            //         // autoHide: 'scroll'
            //     }
            // });

            // $('.weapon-explorer-scrollable', _self.$el).each(function (i, el) {
            //     // OverlayScrollbars(el).update();
            //     if (OverlayScrollbars(el).getState()) {
            //         console.log(OverlayScrollbars(el).getState());
            //     } else {
            //         console.log('scrollbars not initiazlied');
            //     }
            //
            // });

            $('.weapon-mod-item', _self.$el).each(function (i, el) {
                let $toolTip = $('.mod-item-tooltip', $(el));
                Tipped.create($(el), {
                    inline: $toolTip.attr('id'),
                    skin: 'light',
                    radius: false,
                    behavior: 'mouse',
                    padding: false,
                    position: 'top',
                    size: 'large'
                });
            });
            // this.$forceUpdate();
        })
    },
    mounted() {
        const _this = this;
        // console.log('Legendary Weapons component mounted.')
        setsData = this.mergeWeaponVariants();
        // get sets Data
        this.sets = this.filterSets();
        this.filteredSet = [...this.sets];
        this.setIndex = 0;
        this.setNoSlides = this.sets.length;
        this.refreshFirstSlideAssets();
        eventBus.$on('explorer:WeaponsTypeFilter', function (weaponType) {
            _this.sets = _this.filterSets(weaponType);
        });
    },
    methods: {
        testEvent() {
            //  console.log('updated');
        },
        elCreated() {
            //  console.log('created');
        },
        setWeaponClassSizing(weaponType) {
            // return 'content' + (set.group == weaponType ? ' ' + weaponType : '');
            return ' ' + weaponType;
        },
        mergeWeaponVariants() {
            let filteredLegendaries = setsData.filter(x => x.rarity === 'legendary')
            _.forEach(filteredLegendaries, function (weapon) {
                _.forEach(weaponTypes, function (weaponType) {
                    if (weaponType.slug == weapon.group) {
                        weapon.group_title = weaponType.title;
                    }
                })
                _.forEach(variants, function (variant) {
                    if (weapon.variant == variant.id) {
                        weapon.variant_data = variant;
                    }
                });
            });

            _.forEach(filteredLegendaries, function (weapon) {
                let modInfo = [{}, {}];
                let attributesInfo = [{}, {}, {}];
                _.forEach(mods, function (mod) {
                    let modId1 = weapon.mods[0];
                    let modId2 = weapon.mods[1];
                    if (modId1 == mod.id) {
                        modInfo[0] = mod;
                    }
                    if (modId2 == mod.id) {
                        modInfo[1] = mod;
                    }
                });
                _.forEach(weaponAttributes, function (attribute) {
                    let attr1 = weapon.attributes[0];
                    let attr2 = weapon.attributes[1];
                    let attr3 = weapon.attributes[2];
                    if (attribute.id == attr1) {
                        attributesInfo[0] = attribute;
                    }
                    if (attribute.id == attr2) {
                        attributesInfo[1] = attribute;
                    }
                    if (attribute.id == attr3) {
                        attributesInfo[2] = attribute;
                    }
                });
                weapon.mod_info = modInfo;
                weapon.attributes_info = attributesInfo;
            });
            _.forEach(filteredLegendaries, function (val) {
                // console.log(val)
            })

            return filteredLegendaries;
        },
        convertTierToRoman(tierNumber) {
            switch (parseInt(tierNumber)) {
                case 1:
                    return 'I';
                case 2:
                    return 'II';
                case 3:
                    return 'III';
            }
        },
        filterSets(weaponType = 0) {
            let dt, ds, dn = [];
            let activeData = setsData;
            if (parseInt(weaponType) === 0) {
                dn = activeData.filter(x => x.rarity === 'legendary');
            } else {
                let weaponTypeString = '';
                switch (weaponType) {
                    case "1":
                        weaponTypeString = 'assault-rifle';
                        break;
                    case "2":
                        weaponTypeString = 'double-gun';
                        break;
                    case "3":
                        weaponTypeString = 'light-machinegun';
                        break;
                    case "4":
                        weaponTypeString = 'submachine-gun';
                        break;
                    case "5":
                        weaponTypeString = 'automatic-shotgun';
                        break;
                    case "6":
                        weaponTypeString = 'pump-action-shotgun';
                        break;
                    case "7":
                        weaponTypeString = 'automatic-sniper-rifle';
                        break;
                    case "8":
                        weaponTypeString = 'bolt-action-rifle';
                        break;
                    case "9":
                        weaponTypeString = 'rifle';
                        break;
                    case "10":
                        weaponTypeString = 'pistol';
                        break;
                    case "11":
                        weaponTypeString = 'revolver';
                        break;
                }
                // console.log(weaponTypeString);
                dt = activeData.filter(x => x.group == weaponTypeString && x.rarity == 'legendary')
                dn = dt;
            }
            this.setNoSlides = dn.length;
            return dn;
        },
        hasClass(el, className) {
            return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
        },
        addClass(el, className) {
            if (el.classList) el.classList.add(className);
            else if (!this.hasClass(el, className)) el.className += ' ' + className;
        },
        removeClass(el, className) {
            if (el.classList) el.classList.remove(className);
            else el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
        },

        searchData(value) {
            const _this = this;

            this.sets = this.filteredSet;
            let searchFiltered = [];
            let weaponType = 'all'
            switch (this.weaponTypeFilter) {
                case "0":
                    weaponType = 'all';
                    break;
                case "1":
                    weaponType = 'assault-rifle';
                    break;
                case "2":
                    weaponType = 'double-gun';
                    break;
                case "3":
                    weaponType = 'light-machinegun';
                    break;
                case "4":
                    weaponType = 'submachine-gun';
                    break;
                case "5":
                    weaponType = 'automatic-shotgun';
                    break;
                case "6":
                    weaponType = 'pump-action-shotgun';
                    break;
                case "7":
                    weaponType = 'automatic-sniper-rifle';
                    break;
                case "8":
                    weaponType = 'bolt-action-rifle';
                    break;
                case "9":
                    weaponType = 'rifle';
                    break;
                case "10":
                    weaponType = 'pistol';
                    break;
                case "11":
                    weaponType = 'revolver';
                    break;
            }

            if (weaponType === 'all') {
                searchFiltered = this.sets.filter(function (x) {
                    // x => (x.name).toLowerCase().includes(value)
                    let sourceName = x.title;
                    let sourceTargetString = sourceName.toLowerCase();
                    return sourceTargetString.includes(value);
                });
            } else {
                searchFiltered = this.sets.filter(function (x) {
                    // x => (x.name).toLowerCase().includes(value)
                    let sourceName = x.title;
                    let sourceTargetString = sourceName.toLowerCase();
                    return sourceTargetString.includes(value) && x.group === weaponType;
                });
            }
            this.setIndex = 0;
            this.setNoSlides = searchFiltered.length;
            this.sets = searchFiltered;
            this.refreshFirstSlideAssets();
        },
        navigateSetSlide(previousIndex) {
            const _self = this;
            let index = this.setIndex;
            let $targetSlide = $('.set-container.scroll-section', $('#' + this.segmentId)).eq(index);
            let $setCharacterImage = $('.weapon-visual', $targetSlide);
            let $contentImage = $('.content', $setCharacterImage).eq(0);
            let $contentImageSharpen = $('.content', $setCharacterImage).eq(1);
            let contentImageURL = $contentImage.attr('data-img');

            // console.log('previoius slide index: ', previousIndex);
            // console.log('current slide index: ', index);

            // OverlayScrollbars($('.weapon-explorer-scrollable', $targetSlide), {
            //     className: "os-theme-light",
            //     scrollbars: {
            //         autoHide: 'scroll'
            //     }
            // });

            $contentImage.css('background-image', 'url("' + contentImageURL + '")');
            $contentImageSharpen.css('background-image', 'url("' + contentImageURL + '")');
            let $armorPiece = $('.armory-pieces .armory-item', $targetSlide);
            $armorPiece.each(function (i, el) {
                $('.content .visual', $(el)).css('background-image', 'url(' + $('.content .visual', $(el)).attr('data-img') + ')');
            });
            gsap.to($('.explorer-carousel', $('#' + this.segmentId)), {
                xPercent: -1 * index * 100, duration: 0.5, ease: "expo.inOut", onComplete: function () {
                    // remove previous scroller
                    // if (previousIndex !== -1) {
                    //     let $previousSlide = $('.set-container.scroll-section', $('#' + _self.segmentId)).eq(previousIndex);
                    //     let $previousScroller = $('.weapon-explorer-scrollable', $previousSlide);
                    //     // destroy the previous slide scroller
                    //     OverlayScrollbars($previousScroller).destroy();
                    // }
                }
            })
        },
        refreshFirstSlideAssets(index = 0) {
            const _this = this;
            this.setIndex = 0;
            gsap.set($('.explorer-carousel', $('#' + this.segmentId)), {xPercent: 0});
            this.$nextTick(function () {
                let $targetSlide = $('.set-container.scroll-section', $('#' + _this.segmentId)).eq(0);
                let $scrollerElement = $('.weapon-explorer-scrollable', $targetSlide);
                let $setCharacterImage = $('.weapon-visual', $targetSlide);
                let $contentImage = $('.content', $setCharacterImage).eq(0);
                let $contentImageSharpen = $('.content', $setCharacterImage).eq(1);
                let contentImageURL = $contentImage.attr('data-img');

                // OverlayScrollbars($scrollerElement, {
                //     className: "os-theme-light",
                //     scrollbars: {
                //         // autoHide: 'scroll'
                //     }
                // });

                let $armorPiece = $('.armory-pieces .armory-item', $targetSlide);
                $armorPiece.each(function (i, el) {
                    $('.content .visual', $(el)).css('background-image', 'url(' + $('.content .visual', $(el)).attr('data-img') + ')');
                });

                $contentImage.css('background-image', 'url("' + contentImageURL + '")');
                $contentImageSharpen.css('background-image', 'url("' + contentImageURL + '")');
            })
        },
        currentNext(direction) {
            if (direction === 'next') {
                if (this.setIndex < this.setNoSlides - 1) {
                    let previousIndex = this.setIndex;
                    this.setIndex++;
                    this.navigateSetSlide(previousIndex);
                }
            }
            if (direction === 'prev') {
                if (this.setIndex <= 0) {
                    // do nothing
                } else {
                    let previousIndex = this.setIndex;
                    this.setIndex--;
                    this.navigateSetSlide(previousIndex);
                }
            }
            return false;
        },
        getSetImageOnly(filename, weaponType) {
            // return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/weapons/' + weaponType + '/legendary/' + filename;
            return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/legendary-weapons/' + filename;
        },
        resetSetStates() {
            const _this = this;
            let $slides = $('.set-container.scroll-section', $('#' + this.segmentId));
            this.setNoSlides = 0;
            this.setIndex = 0;
            $slides.each(function (i, el) {
                if ($(el).css('display') === 'none') {
                    // it's hidden don't count it
                } else {
                    _this.setNoSlides++;
                }
            })
        },
        getWeaponModImageTag(filename) {
            // console.log(filename);
            return 'background-image: url("https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/mods/weapon/' + filename + '.png")';
        },
        resetAllSetsVisibility() {
            const _this = this;
            let $slides = $('.set-container.scroll-section', $('#' + this.segmentId));
            $slides.each(function (i, el) {
                $(el).css('display', 'flex')
            })
        },
        clearSearch() {
            $('#cs-btn-weapons', this.$el).removeClass('show');
            $('#searchWeaponInput', this.$el).val('');
            this.searchTerm = '';
            this.searchData('');
        },
        clearAndEmmit() {
            this.clearSearch();
        },
        emitTypeValue() {
            const _this = this;
            // if (this.timer) {
            //     clearTimeout(this.timer);
            //     this.timer = null;
            // }
            // this.timer = setTimeout(() => {
            let typedValue = _this.searchTerm.toLowerCase();
            if (typedValue.length > 0) {
                $('#cs-btn-weapons', _this.$el).addClass('show');
            } else {
                $('#cs-btn-weapons', _this.$el).removeClass('show');
            }
            _this.searchData(typedValue);
            // }, 500);
        },
    }
}
</script>
