<template>
    <div class="armory-content">
        <div v-for="mod in modsData" @click="selectModItem(mod)" class="mod-item">
            <div class="item-visual">
                <div class="content" :style="getImage(mod.image)">
                    <div class="filter" :style="getImage(mod.image)"></div>
                    <div class="highlight"></div>
                    <div class="tier-icon">{{ renderModTier(mod.tier) }}</div>
                </div>
            </div>
            <div class="item-title">
                {{ mod.name }}
            </div>
            <div class="mod-item-tooltip" :id="'mod-tooltip-'+mod.id">
                <div class="mod-item-tooltip-header">{{ mod.name }}</div>
                <div class="mod-item-tooltip-body"><span v-html="mod.description"></span></div>
            </div>
        </div>
    </div>
</template>
<script>

import store from '../store'
import eventBus from "../eventBus";
import mods from '../mods.json';

export default {
    data: function () {
        return {
            builds: this.getData,
            caller: null,
            modsData: null,
            modType: '',
            gearType: '',
            selectedClass: ''
        }
    },
    beforeUpdate() {
        let _self = this;
        $('.mod-item', this.$el).each(function (i, el) {
            Tipped.remove($(el));
        });
    },
    updated() {
        let _self = this;
        this.$nextTick(function () {
            $('.mod-item', _self.$el).each(function (i, el) {
                let $toolTip = $('.mod-item-tooltip', $(el));
                Tipped.create($(el), {
                    inline: $toolTip.attr('id'),
                    skin: 'light',
                    radius: false,
                    behavior: 'mouse',
                    padding: false,
                    position: 'top',
                    size: 'large'
                });
            });
            // this.$forceUpdate();
        })
    },
    computed: {
        getBuilds() {
            this.builds = store.builds;
            return store.builds;
        },
    },
    mounted() {
        // console.log('Builder Modding Library component mounted.')
        let _this = this;
        // this.getModData();

        // listen to search events from Input component for mods library
        eventBus.$on('armory:SearchMods', function (value) {
            // console.log('searching through mods in the mod panel with string value', value);
            $('.mod-item', _this.$el).filter(function () {
                let itemTitle = $('.item-title', $(this)).text().toLowerCase();
                $(this).toggle(itemTitle.indexOf(value) > -1);
            });
        })

        eventBus.$on('armory:ModFilter', function (modTier) {
            // console.log('modTier: ', modTier);
            _this.modsData = _this.sortData(modTier);
        });

        // listen to click initiator for this specific mod slot on the builder
        // it allows us to identify which mod to fill with data from the select mod
        eventBus.$on('armory:ModInitiator', function (caller) {
            _this.caller = caller.$element;
            _this.modType = caller.modType;
            _this.gearType = caller.gearType;
            _this.selectedClass = caller.class;
            eventBus.$emit('armory:ModFilter', '0');
            eventBus.$emit('armory:ChangeTierSelect', '0');
            eventBus.$emit('armory:ClearModSearch', '');
        })
    },
    created() {
        //
    },
    methods: {
        setToolTip(id) {
            return id;
        },
        sortData(modTier) {
            let dt, ds, dn = [];
            if (modTier === '0') {
                if (this.selectedClass) {
                    dt = mods.filter(x => x.type === this.modType && (x.class === this.selectedClass || x.class === 'universal'))
                } else {
                    dt = mods.filter(x => x.type === this.modType)
                }
                dn = dt.sort((a, b) => a.tier.localeCompare(b.tier) || a.name.localeCompare(b.name));
            } else {
                if (this.selectedClass) {
                    dt = mods.filter(x => x.type === this.modType && x.tier === modTier && (x.class === this.selectedClass || x.class === 'universal'))
                } else {
                    dt = mods.filter(x => x.type === this.modType && x.tier === modTier)
                }
                dn = dt.sort((a, b) => a.tier.localeCompare(b.tier) || a.name.localeCompare(b.name));

            }
            return dn;
        },
        getModData() {
            let dt = mods.filter(x => x.type === this.modType)
            let ds = dt.sort((a, b) => a.tier.localeCompare(b.tier));
            let dn = ds.sort((a, b) => a.name.localeCompare(b.name));
            this.modsData = dn;
        },
        renderModTier(tierLevel) {
            switch (tierLevel) {
                case "1":
                    return 'I';
                    break;
                case "2":
                    return 'II';
                    break;
                case "3":
                    return 'III';
                    break;
            }
        },
        selectModItem(modDataItem) {
            const self = this;
            eventBus.$emit('armory:SelectMod', {
                mod_caller: self.caller,
                mod_gear_type: self.gearType,
                mod_id: modDataItem.id,
                mod_type: modDataItem.type,
                mod_tier: modDataItem.tier,
                mod_image: self.getImageURL(modDataItem.image),
                mod_name: modDataItem.name,
            });
        },
        getImageURL(filename) {
            let prefix = '';

            // console.log(this.selectedClass, 'selectedClass');
            //
            // if (this.selectedClass !== '') {
            //     prefix = this.selectedClass.toLowerCase() + '-';
            // }

            if (this.modType == 'weapon') {
                return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/mods/weapon/' + filename + '.png';
            }
            if (this.modType == 'armor') {
                return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/mods/armor/' + filename + '.jpg';
            }
            return false;
        },
        getImage(filename) {
            let prefix = '';

            // if (this.selectedClass) {
            //     prefix = this.selectedClass.toLowerCase() + '-';
            //     console.log("https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/mods/armor/' + filename + '.jpg");
            // } else {
            //     prefix = '';
            // }

            if (this.modType == 'weapon') {
                return 'background-image: url("https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/mods/weapon/' + filename + '.png")';
            }
            if (this.modType == 'armor') {
                return 'background-image: url("https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/mods/armor/' + filename + '.jpg")';
            }
            return false;
        }
    }
}
</script>
