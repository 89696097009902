import store from "./store";
import eventBus from "./eventBus";
import mods from "./mods.json";
import mediaelementplayer from "mediaelement";
require('./bootstrap');
import technomancerTree from './technomancer-tree.json';
import pyromancerTree from './pyromancer-tree.json';
import tricksterTree from './trickster-tree.json';
import devastatorTree from './devastator-tree.json';

import assaultRifleData from './levels/assault-rifle-multipliers.json';
import automaticShotgunData from './levels/automatic-shotgun-multipliers.json';
import automaticSniperRifleData from './levels/automatic-sniper-rifle-multipliers.json';
import boltActionRifleData from './levels/bolt-action-rifle-multipliers.json';
import doubleGunData from './levels/double-gun-multipliers.json';
import lightMachinegunData from './levels/light-machinegun-multipliers.json';
import pistolData from './levels/pistol-multipliers.json';
import pumpActionShotgunData from './levels/pump-action-shotgun-multipliers.json';
import revolverData from './levels/revolver-multipliers.json';
import rifleData from './levels/rifle-multipliers.json';
import submachineGunData from './levels/submachine-gun-multipliers.json';
import gearData from './levels/gear-multipliers.json';

$(function () {
    console.log('Application started...');

    $('a, input, select, textarea').attr('tabindex', '-1');
    $('video, audio').mediaelementplayer({
        // Do not forget to put a final slash (/)
        // pluginPath: 'https://cdnjs.com/libraries/mediaelement/',
        // this will allow the CDN to use Flash without restrictions
        // (by default, this is set as `sameDomain`)
        // shimScriptAccess: 'always'
        // more configuration
    });

    let calculatorData = {
        'assault-rifle': assaultRifleData,
        'automatic-shotgun': automaticShotgunData,
        'automatic-sniper-rifle': automaticSniperRifleData,
        'bolt-action-rifle': boltActionRifleData,
        'double-gun': doubleGunData,
        'light-machinegun': lightMachinegunData,
        'pistol': pistolData,
        'pump-action-shotgun': pumpActionShotgunData,
        'revolver': revolverData,
        'rifle': rifleData,
        'submachine-gun': submachineGunData,
        'armor': gearData,
    }

    let armorData = gearData;

    let csfr_token = document.head.querySelector('meta[name="csrf-token"]');
    let processingCustomCharacter = false;
    let creditsOut = false;
    let helpOut = false;
    // define variables and constants
    let guidesTabOpen = false;
    let builderLibraryTabOpen = false;
    let builderLibraryModsTabOpen = false;
    let windowHeight = $(window).innerHeight();
    window.defaultDuration = 0.5;
    let defaultParallaxDuration = 1;
    let resizeTimeout;
    let selectedClass = null;
    let headerBounds,
        footerBounds,
        innerContentDiff,
        guidesBtnTop,
        topMenuBottomHeight,
        topNavBottomHeight,
        innerContentDiffShort,
        segmentHeader,
        guidesTabContainerHeight
    let classRootPrefix = null
    let $nodes = null;
    let $clickableNodes = null;
    let $classTree = null;
    let $skillTree = null;
    let activeNodesArr = [];
    let classTree = null;

    let weaponObject = {
        type: 'assault-rifle',
        rarity: 'rare',
        level: 10,
        value: 0,
    };
    let armorObject = {
        type: 'headgear',
        rarity: 'rare',
        level: 10,
        value: 0,
    };
    isUserLoggedIn();
    // Debugging
    // jump to editor right away on load so I don't have to keep clicking
    // gsap.set($('.segments'), {
    //     xPercent: -200
    // });
    // gsap.set($('.subsegment-container-carousel'), {
    //     xPercent: -200
    // });

    renderCheckAndAdjust();

    // ********************************************************************
    // **** INITIALIZE EVENTS
    // ********************************************************************
    // set the height for segments containers
    setContentHeights();

    // register all select dropdowns for styling but native on mobile
    easydropdown.all({
        behavior: {
            maxVisibleItems: 6,
            showPlaceholderWhenOpen: false,
            liveUpdates: true,
        },
        callbacks: {
            onOpen: function (value, selectObject, edd) {
                // console.log(edd.dom.select);
            },
            onSelect: function (value, select) {
                let $parentSelectGroup = $(select).parent().parent().parent();
                if ($parentSelectGroup.hasClass('gear-attributes-chooser')) {
                    let arrayNode = $(select).attr('data-node') - 1;
                    let selectID = $(select).attr('id');
                    let withoutPrefix = selectID.substr(7);
                    let gearType = withoutPrefix.substr(0, withoutPrefix.length - 2);
                    // console.log(gearType);
                    // console.log(arrayNode);
                    store.builderData.gear[gearType].attributes[arrayNode] = parseInt(value);
                }
                disableDuplicateAttributes(value, select);
            }
        }
    });

    // set up informational tooltips for the app
    Tipped.create($('#info-screenshot-icon'), {
        inline: 'screenshot-upload-tooltip',
        skin: 'light',
        radius: false,
        behavior: 'mouse',
        padding: false,
        position: 'right',
        size: 'large',
    });

    $('.credits-btn').click(function (e) {
        if (creditsOut) {
            $('#credits-tooltip').removeClass('show');
            creditsOut = false;
        } else {
            $('#credits-tooltip').addClass('show');
            creditsOut = true;
        }
    })

    $('.help-btn').click(function (e) {
        if (helpOut) {
            $('#help-tooltip').removeClass('show');
            helpOut = false;
        } else {
            $('#help-tooltip').addClass('show');
            helpOut = true;
        }
    })

    $('#credits-community-btn').click(function (e) {
        gsap.to($('.credits-pages-slider'), {xPercent: -100, ease: "expo.inOut", duration: 0.5});
    })
    $('#credits-back-btn').click(function (e) {
        gsap.to($('.credits-pages-slider'), {xPercent: 0, ease: "expo.inOut", duration: 0.5});
    })

    $('#help-main-btn').click(function (e) {
        gsap.to($('.help-pages-slider'), {xPercent: -100, ease: "expo.inOut", duration: 0.5});
    })
    $('#help-back-btn').click(function (e) {
        gsap.to($('.help-pages-slider'), {xPercent: 0, ease: "expo.inOut", duration: 0.5});
    })

    $(document).on('click', function (e) {
        let $creditsPanel = $('#credits-tooltip');
        let $helpPanel = $('#help-tooltip');
        if (e.target != $creditsPanel[0] && !$creditsPanel[0].contains(e.target) && e.target != $('.credits-btn')[0]) {
            $('#credits-tooltip').removeClass('show');
            creditsOut = false;
        }

        if (e.target != $helpPanel[0] && !$helpPanel[0].contains(e.target) && e.target != $('.help-btn')[0]) {
            $('#help-tooltip').removeClass('show');
            helpOut = false;
        }
    })
    // let creditsTipped = Tipped.create($('.credits-btn'), {
    //     inline: 'credits-tooltip',
    //     skin: 'dark',
    //     radius: false,
    //     // behavior: 'mouse',
    //     padding: false,
    //     position: 'top',
    //     size: 'large',
    //     close: true,
    //     hideOn: false,
    //     stem: false,
    //     hideOnClickOutside: true
    // });


    // ********************************************************************
    // **** CHECK HASH FOR INITIAL LOAD TO JUMP TO
    // ********************************************************************
    // checkHash();

    // ********************************************************************
    // **** REGISTER OVERLAY SCROLLBARS TO REPLACE NATIVE SCROLLING
    // ********************************************************************
    OverlayScrollbars(document.querySelectorAll("#build-description"), {
        className: "os-theme-light",
        scrollbars: {
            // autoHide: 'scroll'
        }
    });

    OverlayScrollbars(document.querySelectorAll(".build-container"), {
        className: "os-theme-light",
        scrollbars: {
            autoHide: 'scroll'
        }
    });

    OverlayScrollbars(document.querySelectorAll(".segment.scroll"), {
        className: "os-theme-light",
        scrollbars: {
            autoHide: 'scroll'
        }
    });
    OverlayScrollbars(document.querySelectorAll(".build-list"), {
        className: "os-theme-light",
        scrollbars: {
            autoHide: 'scroll'
        }
    });
    OverlayScrollbars(document.querySelectorAll(".segment-content.scroll"), {
        className: "os-theme-light",
        scrollbars: {
            autoHide: 'scroll'
        }
    });

    $('select').click(function () {
        let currentValue = $(this).val();
        if ($(this).data('click')) {
            $(this).addClass('changed');
            $(this).data('click', false);
        } else {
            $(this).removeClass('changed');
            $(this).data('click', true);
        }
    });

    // ********************************************************************
    // **** CLICK EVENTS
    // ********************************************************************
    $('.classtree-close-btn').click(function (e) {
        const btn = document.querySelector('#class-tree-btn');
        const panel = document.querySelector('.class-trees');

        gsap.set($('.classtree-container'), {
            display: 'none', onStart: function () {
                $('.classtree-container').removeClass('active');
            }, onComplete: function () {
                gsap.to($(this), {
                    duration: 0,
                    delay: 0.2,
                    onComplete: function () {
                        let state = Flip.getState('#class-tree-btn');
                        btn.style.visibility = "visible";
                        panel.style.display = "block";
                        Flip.to(state, {
                            targets: panel,
                            duration: 0.6,
                            ease: 'expo.inOut',
                            onStart: function () {
                                // gsap.to($('.classtree-container'), {
                                //     opacity: 0,
                                //     duration: 0.3,
                                //     ease: 'expo.out',
                                // })
                                gsap.fromTo($(panel), {
                                    borderRadius: '0',
                                    backgroundColor: '#0F1216',
                                }, {
                                    borderRadius: '5em',
                                    backgroundColor: '#dbaa43',
                                    duration: 0.5,
                                    ease: 'expo.out',
                                });
                            },
                            onComplete: function () {
                                gsap.set($('.class-trees'), {
                                    css: {
                                        width: "100%",
                                        height: "100%",
                                        transform: 'unset',
                                        top: "0",
                                        left: "0",
                                        display: "none"
                                    }
                                })
                                gsap.to($('#class-tree-btn'), {
                                    duration: 0.6,
                                    onComplete: function () {
                                        $('#class-tree-btn').removeClass('inflate');
                                    }
                                })
                                gsap.to($('#class-tree-btn'), {
                                    duration: 0.3,
                                    css: {
                                        width: '100%',
                                        borderRadius: '0',
                                        height: '40px',
                                    },
                                    ease: 'expo.inOut',
                                })
                            }
                        })
                    }
                })
            }
        });

    });
    $('#class-tree-btn').click(function (e) {
        if ($(this).hasClass('unavailable')) {
            // disabled
        } else {
            const btn = document.querySelector('#class-tree-btn');
            const panel = document.querySelector('.class-trees');
            $(this).addClass('inflate');
            gsap.to($(this), {
                duration: 0.3,
                css: {
                    width: '40px',
                    borderRadius: '5em',
                    height: '40px',
                },
                ease: 'expo.inOut'
            })

            gsap.to($(this), {
                duration: 0.6,
                onComplete: function () {
                    let state = Flip.getState('#class-tree-btn');
                    btn.style.visibility = "hidden";
                    panel.style.display = "block";
                    Flip.from(state, {
                        targets: panel,
                        duration: 0.5,
                        ease: 'expo.inOut',
                        onStart: function () {
                            gsap.fromTo($(panel), {
                                css: {
                                    borderRadius: '5em',
                                    backgroundColor: '#dbaa43',
                                }
                            }, {
                                css: {
                                    borderRadius: '0em',
                                    backgroundColor: '#0F1216',
                                },
                                duration: 0.5,
                                ease: 'expo.out',
                                onComplete: function () {
                                    gsap.set($('.classtree-container'), {
                                        display: 'block'
                                    });
                                    showActiveTree(selectedClass);
                                    // gsap.set($('.classtree-container'), {display: 'visible'});
                                    // gsap.to($('.classtree-container'), {
                                    //     opacity: 1,
                                    //     duration: 0.5,
                                    //     ease: 'expo.out'
                                    // })
                                }
                            });
                        },
                    })
                }
            })
        }
    })
    $('.class-icon').click(function (e) {
        let characterClass = $(this).attr('data-class');
        if (characterClass === selectedClass) {
            // do nothing
        } else {
            let pos = $(this).attr('data-id')

            resetSkills($('#skills-' + characterClass))

            $(this).addClass('selected');

            gsap.to($('.skills-carousel'), {duration: 0.5, xPercent: (-1 * pos) * 100, ease: 'expo.inOut'});

            store.builderData.class = selectedClass = characterClass;

            classRootPrefix = '.skill-tree-container.' + selectedClass;
            $nodes = $("path, g, rect", $(classRootPrefix + ' #classtree #skill-tree #nodes'));
            $clickableNodes = $("rect, > g:not(.active-root)", $(classRootPrefix + ' #classtree #skill-tree #nodes'));
            $classTree = $(classRootPrefix + ' #classtree');
            $skillTree = $('#skill-tree', $classTree);

            // assign node points data and skill tree data for the selected class
            switch (selectedClass) {
                case "technomancer":
                    classTree = technomancerTree;
                    break;
                case "pyromancer":
                    classTree = pyromancerTree;
                    break;
                case "trickster":
                    classTree = tricksterTree;
                    break;
                case "devastator":
                    classTree = devastatorTree;
                    break;
            }

            $('#class-tree-btn').removeClass('unavailable');

            resetAllClassTrees();
            resetTree();
        }
    });
    $('.skill-icon').click(function (e) {
        if (store.builderData.skills.length >= 3) {
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                removeArrElementByValue(store.builderData.skills, parseInt($(this).attr('data-id')));
                // console.log(store.builderData.skills);
            }
        } else {
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                removeArrElementByValue(store.builderData.skills, parseInt($(this).attr('data-id')));
                // console.log(store.builderData.skills);
            } else {
                $(this).addClass('selected');
                store.builderData.skills.push(parseInt($(this).attr('data-id')))
                // console.log(store.builderData.skills);
            }
        }
    })
    $('#twitter-connect').click(function (e) {
        let url = $(this).attr('data-url');
        popupCenter({url: url, title: 'Connect to Outriders Outpost', w: 600, h: 400});
    });

    $('#google-connect').click(function (e) {
        let url = $(this).attr('data-url');
        popupCenter({url: url, title: 'Connect to Outriders Outpost', w: 600, h: 400});
    });

    $('#twitch-connect').click(function (e) {
        let url = $(this).attr('data-url');
        popupCenter({url: url, title: 'Connect to Outriders Outpost', w: 600, h: 400});
    });

    $('#discord-connect').click(function (e) {
        let url = $(this).attr('data-url');
        popupCenter({url: url, title: 'Connect to Outriders Outpost', w: 600, h: 400});
    });

    $('#back-to-builds').click(function (e) {
        if (processingCustomCharacter) {
            return false;
        } else {
            let slideX = 0;

            // if (store.user.status) {
            //     slideX = 0;
            // }
            gsap.to($('.subsegment-container-carousel'), {
                duration: defaultDuration,
                xPercent: slideX,
                ease: 'expo.inOut',
                onComplete: function () {
                    $('.class-trees-content.' + selectedClass + ' #resetTreeBtn').trigger('click');
                    clearBuilder();
                    eventBus.$emit('library:GetUserBuilds', {});
                }
            });
        }
    })
    $('#newBuildBtn').click(function (e) {
        let slideX = -100;

        // if (store.user.status) {
        //     slideX = -100;
        // }
        gsap.to($('.subsegment-container-carousel'), {
            duration: defaultDuration,
            xPercent: slideX,
            ease: 'expo.inOut'
        });
        // resetStoreBuilderData();
        // populateBuilder();
        clearBuilder();
        if (store.builderData.web_id) {
            $('#viewbuild-btn').removeClass('inactive');
        } else {
            $('#viewbuild-btn').addClass('inactive');
        }

        $('span', $('#saveBuildBtn')).text('Save')
        $('i', $('#saveBuildBtn')).removeClass('fa-link').addClass('fa-floppy-disk-pen');
    });
    $('#simple-build-btn').click(function (e) {
        gsap.to($('.subsegment-container-carousel'), {
            duration: defaultDuration,
            xPercent: -100,
            ease: 'expo.inOut'
        });
        $('#viewbuild-btn').addClass('inactive');
        $('span', $('#saveBuildBtn')).text('View & Share')
        $('i', $('#saveBuildBtn')).removeClass();
        $('i', $('#saveBuildBtn')).addClass('fa-solid').addClass('fa-link');
        store.user.status = false;
        clearBuilder();
        if (store.builderData.character) {
            $('.character-module').addClass('active');
            $('img', $('.character-image')).attr('src', store.builderData.character);
            $('img', $('.character-image')).css('display', 'initial');
        } else {
            $('.character-module').removeClass('active')
            $('img', $('.character-image')).css('display', 'none');
            $('img', $('.character-image')).attr('src', '');
        }
    });

    $('#viewbuild-btn').click(function (e) {
        window.open(document.location.origin + '/build/' + store.builderData.web_id, '_blank');
    });

    $('#saveBuildBtn').click(function (e) {
        if (store.user.status) {
            // do save for the logged in user
            if (processingCustomCharacter) {
                return false;
            } else {
                saveBuild();
            }

        } else {
            // do stuff for the anonymous build
            if (processingCustomCharacter) {
                return false;
            } else {
                saveAnonymousBuild();
            }
        }
    })

    $('.guides-btn').click(function (e) {
        // window.location.hash = 'explorer?something';
        e.preventDefault();
        if (guidesTabOpen) {
            hideGuidesTab();
        } else {
            showGuidesTab();
        }
    });

    $('.gear-icon').click(function (e) {
        builderLibraryTabOpen = true;
        let $parent = $(this).parent().parent();
        let gearType = $parent.attr('id').substring(5);
        if (gearType === 'primary' || gearType === 'secondary' || gearType === 'sidearm') {
            $('.armory-header', $('.builder-library.armory')).find('.label-text').text('SEARCH ' + gearType.toUpperCase() + ' WEAPONS');
            $('.field-label.armory.mt-2', $('.builder-library.armory')).find('.label-text').text('CHOOSE ' + gearType.toUpperCase() + ' WEAPONS');
        } else {
            $('.armory-header', $('.builder-library.armory')).find('.label-text').text('SEARCH ' + gearType.toUpperCase() + ' ARMOR');
            $('.field-label.armory.mt-2', $('.builder-library.armory')).find('.label-text').text('CHOOSE ' + gearType.toUpperCase() + ' ARMOR');
        }
        eventBus.$emit('armory:GearInitiator', {
            $element: $(this),
            class: selectedClass,
            gearType: gearType
        })
        showBuilderLibraryTab();

    });
    $('.gear-mod-icon').click(function (e) {
        builderLibraryModsTabOpen = true;
        let gearType = $(this).parent().parent().parent().parent().attr('id').substring(5);
        eventBus.$emit('armory:ModInitiator', {
            $element: $(this),
            class: selectedClass,
            modType: $(this).attr('data-mod-type'),
            gearType: gearType
        })
        // eventBus.$emit('armory:ResetEverything', {})
        showBuilderModsLibraryTab();
    });

    $('.builderlibrary-btn', $('.builder-library.armory')).click(function (e) {
        // window.location.hash = 'explorer?something';
        e.preventDefault();
        if (builderLibraryTabOpen) {
            hideBuilderLibraryTab();
        } else {
            showBuilderLibraryTab();
        }
    });
    $('.builderlibrary-btn', $('.builder-library.mods')).click(function (e) {
        // window.location.hash = 'explorer?something';
        e.preventDefault();
        if (builderLibraryModsTabOpen) {
            hideBuilderModsLibraryTab();
        } else {
            showBuilderModsLibraryTab();
        }
    });

    $('#weapon-calculator').click(function (e) {
        // window.location.hash = 'weapon-calculator';
        resetNavTabs();
        $(this).addClass('active');
        gsap.to($('.visual-container'), {
            duration: defaultParallaxDuration, xPercent: 0, ease: 'expo.inOut', onComplete: function () {
                //
            }
        });
        gsap.to($('.segments'), {
            duration: defaultDuration, xPercent: 0, ease: 'expo.inOut', onComplete: function () {
                //
            }
        });
    });
    $('#armor-calculator').click(function (e) {
        // window.location.hash = 'armor-calculator';
        resetNavTabs();
        $(this).addClass('active');
        gsap.to($('.visual-container'), {
            duration: defaultParallaxDuration, xPercent: -33.3, ease: 'expo.inOut', onComplete: function () {
                //
            }
        });
        gsap.to($('.segments'), {
            duration: defaultDuration, xPercent: -100, ease: 'expo.inOut', onComplete: function () {
                //
            }
        });
    });
    $('#build-creator').click(function (e) {
        // window.location.hash = 'build-creator/login';
        resetNavTabs();
        $(this).addClass('active');
        gsap.to($('.visual-container'), {
            duration: defaultParallaxDuration, xPercent: -66.6, ease: 'expo.inOut', onComplete: function () {
                //
            }
        });
        gsap.to($('.segments'), {
            duration: defaultDuration, xPercent: -200, ease: 'expo.inOut', onComplete: function () {
                //
            }
        });
    });

    // rarity checkboxes on builder for each gear type
    // to change the background color of the item preview box
    $('.btn-check').on('click', function (e) {
        // console.log($(this).parent().parent().parent().parent());
        let $parent = $(this).parent().parent().parent().parent().parent();
        // console.log('BTN-CHECK parent: ', $parent);
        let gearType = $parent.attr('id').substring(5);
        // console.log(gearType);
        let $gearIconBackground = $('.gear-icon-background', $parent);
        $gearIconBackground.removeClass('common').removeClass('unusual').removeClass('rare').removeClass('epic').removeClass('legendary');
        $gearIconBackground.addClass($(this).val());
        store.builderData.gear[gearType].item.rarity = $(this).val();
    });

    $('.remove-character', $('.character-image')).click(function (e) {
        $('.character-module').removeClass('active')
        $('img', $('.character-image')).attr('src', '');
        store.builderData.character = '';
        store.builderData.character_screenshot = '';
        quickSaveBuild();
    })
    // ********************************************************************
    // **** HELPER CLICK EVENTS
    // ********************************************************************
    $('#test-build-item').click(function (e) {
        gsap.to($('.subsegment-container-carousel'), {
            duration: defaultDuration,
            xPercent: -200,
            ease: 'expo.inOut'
        });
    });

    // ********************************************************************
    // **** EVENTS
    // ********************************************************************
    eventBus.$on('explorer:ClosePanel', function () {
        $('.guides-btn').trigger('click');
    });

    $(window).on('hashchange', function (e) {
        // console.log('hashchange', e)
        // console.log(window.location.hash);
        // console.log(window.location.hash.split('/'));
    });
    $(window).on('popstate', function (e) {
        // console.log(e);
    });
    $(window).on('resize load orientationchange', function (e) {
        if (detectMediaQuery() === 'mq-mobile') {
            // Do stuff for small screens etc
            // console.log('App is on mobile')
            store.system.device = 'mobile';
        }
        if (detectMediaQuery() === 'mq-desktop') {
            // console.log('App is on desktop')
            store.system.device = 'desktop';
        }

        store.system.breakpoint = detectMediaBreakpoints();

        clearTimeout(resizeTimeout);
        // Call 'onResize' function after a set delay
        resizeTimeout = setTimeout(detectMediaQuery, 100);
        resizeTimeout = setTimeout(detectMediaBreakpoints, 100);
        // if (store.system.device == 'mobile') {
        //     if ($(document).innerHeight() < $(document).innerWidth()) {
        //         $('#portrait-notice').addClass('show');
        //     } else {
        //         $('#portrait-notice').removeClass('show');
        //     }
        // }
        // if (store.system.device == 'mobile') {
        //     if ($(document).innerHeight() < $(document).innerWidth()) {
        //         $('#portrait-notice').addClass('show');
        //     } else {
        //         $('#portrait-notice').removeClass('show');
        //     }
        // }
        setContentHeights();
    });

    // input file upload for character screenshot
    $('#screenshot-upload').on('change', function (e) {

        const fileList = e.target.files;
        if (!fileList.length) return;
        // console.log(fileList[0]);
        $('#screenshot-upload').prop('disabled', true);

        if (!processingCustomCharacter) {
            processingCustomCharacter = true;

            let formData = new FormData();
            let btnLabel = $('span', $('.screenshot-file-upload')).text();
            let currentClass = $('i', $('.screenshot-file-upload')).attr('class');
            $('i', $('.screenshot-file-upload')).removeClass();
            $('i', $('.screenshot-file-upload')).addClass('fa-solid fa-cog fa-spin');
            $('span', $('.screenshot-file-upload')).text('Processing Upload...');
            axios
                .post('/api/builds/store', {
                    data: store.builderData
                })
                .then(function (response) {
                    // console.log('quick saved and assigned ids for build: ');
                    store.builderData.id = response.data.saved_build_id;
                    store.builderData.web_id = response.data.saved_web_id;
                }).finally(function () {
                formData.append('uploaded_file', fileList[0], fileList[0].name)
                formData.append('web_id', store.builderData.web_id)
                formData.append('id', store.builderData.id)
                const config = {headers: {'content-type': 'multipart/form-data'}}
                axios
                    .post('/api/upload/screenshot', formData, config)
                    .then(function (response) {
                        // console.log(response);
                        // console.log(response.data.character);
                        // console.log(response.data.screenshot);
                        // console.log('from - finally: ');
                        let cachebuster = Math.round(new Date().getTime() / 1000);
                        store.builderData.character = response.data.character;
                        store.builderData.character_screenshot = response.data.screenshot;
                        // console.log('now do a quick save again to save the screenshot character');
                        $('.character-module').addClass('active');
                        $('img', $('.character-image')).attr('src', store.builderData.character);
                        $('img', $('.character-image')).css('display', 'initial');
                        quickSaveBuild();
                        $('i', $('.screenshot-file-upload')).removeClass();
                        $('i', $('.screenshot-file-upload')).attr('class', currentClass);
                        $('span', $('.screenshot-file-upload')).text(btnLabel);
                        processingCustomCharacter = false;
                        $('#screenshot-upload').prop('disabled', false);
                        $('#screenshot-upload').val('');
                        // if (response.status === 'fail') {
                        //     console.log(response.errors);
                        // }
                    })
                    .catch(function (error) {
                        processingCustomCharacter = true;
                    })
            })
        }
        // $.ajaxSetup({
        //     headers: {
        //         'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        //     }
        // });
        //
        // $.ajax({
        //     type: "POST",
        //     enctype: 'multipart/form-data',
        //     url: "/api/upload/screenshot",
        //     data: formData,
        //     processData: false,
        //     contentType: false,
        //     cache: false,
        //     success: function (r) {
        //         console.log(r);
        //     }
        // });
    });

    // check for builder changes in the build description input
    $('#build-description').on('input', function () {
        store.builderData.description = $(this).val();
        // do something
    }).focusout(function () {
        store.builderData.description = $(this).val();
    });
    // check for builder changes in the build title input
    $('#build-title').on('input', function () {
        store.builderData.title = $(this).val();
    }).focusout(function () {
        store.builderData.title = $(this).val();
    });

    // check for builder changes in the build twitch input
    $('#build-twitch').on('input', function () {
        store.builderData.twitch = $(this).val();
        // do something
    }).focusout(function () {
        store.builderData.twitch = $(this).val();
    });

    // check for builder changes in the build youtube input
    $('#build-youtube').on('input', function () {
        store.builderData.youtube = $(this).val();
        // do something
    }).focusout(function () {
        store.builderData.youtube = $(this).val();
    });

    // check for builder changes in the build discord input
    $('#build-discord').on('input', function () {
        store.builderData.discord = $(this).val();
        // do something
    }).focusout(function () {
        store.builderData.discord = $(this).val();
    });

    eventBus.$on('armory:SelectGear', function (data) {
        // console.log('gear selected');
        hideBuilderLibraryTab();
        // console.log(data);
        assignSelectedGear(data)
    });

    eventBus.$on('user:Logout', function () {
        $('#builds-library-section').addClass('inactive');
        $('#builds-login-section').removeClass('inactive');
        gsap.to($('.subsegment-container-carousel'), {
            duration: defaultDuration,
            xPercent: 0,
            ease: 'expo.inOut',
            onComplete: function () {

            }
        });
    });
    eventBus.$on('builder:BuildDetails', function (build) {
        // console.log('builder:BuildDetails');
        let slideX = -100;
        gsap.to($('.subsegment-container-carousel'), {
            duration: defaultDuration,
            xPercent: slideX,
            ease: 'expo.inOut',
            onComplete: function () {
                clearBuilder();
                store.builderData = build.builder_data;
                // console.log('loading build with builder data: ', build.builder_data);
                populateBuilder();
                $('#viewbuild-btn').removeClass('inactive');
                $('span', $('#saveBuildBtn')).text('Save')
                $('i', $('#saveBuildBtn')).removeClass('fa-link').addClass('fa-floppy-disk-pen');
            }
        });
    })

    eventBus.$on('armory:SelectMod', function (data) {
        // console.log('mod selected');
        hideBuilderModsLibraryTab();
        assignSelectedMod(data)
    });

    function assignSelectedMod(data) {
        let romanTier = data.mod_tier;
        switch (data.mod_tier) {
            case "1":
                romanTier = 'I';
                break;
            case "2":
                romanTier = 'II';
                break;
            case "3":
                romanTier = 'III';
                break;
        }
        let $parentContainer = data.mod_caller.parent();
        let $modtier = $('.mod-tier', $parentContainer).text(romanTier);
        $('.gear-mod-label', $parentContainer).text(data.mod_name);
        $('.gear-mod-icon .content', $parentContainer).css('background-image', 'url(' + data.mod_image + ')');
        $('.gear-mod-icon', $parentContainer).addClass('populated');

        // console.log('MOD PARENT', $parentContainer.index());
        // console.log(data);
        store.builderData.gear[data.mod_gear_type].mods[$parentContainer.index()] = data.mod_id;
    }

    function assignSelectedGear(data) {
        let $targetContainer = $('#gear-' + data.gear_type);

        $('.gear-title', $targetContainer).text(data.gear_title);
        $('.gear-icon .content', $targetContainer).css('background-image', 'url(' + data.gear_image + ')');
        $('.gear-icon', $targetContainer).addClass('populated');

        store.builderData.gear[data.gear_type].item.name = data.gear_title;
        store.builderData.gear[data.gear_type].item.id = data.gear_id;
        store.builderData.gear[data.gear_type].item.image = data.gear_image;
        store.builderData.gear[data.gear_type].item.rarity = data.gear_rarity;
        store.builderData.gear[data.gear_type].item.type = data.gear_type;


        if (data.gear_attr_defaults.length > 0 && data.gear_rarity === 'legendary') {
            let gearAttrArr = [...data.gear_attr_defaults];
            let selectValues = [];

            $('.gear-attributes-chooser select', $targetContainer).each(function (i, el) {
                let optionArr = [];
                // loop through selects for target container
                $('option', $(el)).each(function (j, option_el) {
                    if ($(option_el).val()) {
                        optionArr.push(parseInt($(option_el).val()));
                    }
                });
                selectValues.push(optionArr);
            });
            _.forEach(selectValues, function (arr, index) {
                let t = gearAttrArr.some(function (arrEl) {
                    if (arr.includes(arrEl)) {
                        gearAttrArr.splice(gearAttrArr.indexOf(arrEl), 1);
                        $('.gear-attributes-chooser select', $targetContainer).eq(index).val(arrEl);
                        return true;
                    }
                    return false;
                });
            });

            // console.log(data.gear_mods_data);

            // assign the mods from the legendary
            if (data.gear_mods_data) {
                if (data.gear_mods_data[0].mod_id) {
                    let $mod_slot_1 = $('.gear-mod-icon', $targetContainer).eq(0)
                    data.gear_mods_data[0].mod_caller = $mod_slot_1;
                    assignSelectedMod(data.gear_mods_data[0]);
                }

                // assign the mods from the legendary
                if (data.gear_mods_data[1].mod_id) {
                    let $mod_slot_2 = $('.gear-mod-icon', $targetContainer).eq(1)
                    data.gear_mods_data[1].mod_caller = $mod_slot_2;
                    assignSelectedMod(data.gear_mods_data[1]);
                }
            }

            store.builderData.gear[data.gear_type].attributes = data.gear_attr_defaults;
            // store.builderData.gear[data.gear_type].item.mods.push(data.gear_mods_data[0].mod_id);
            // store.builderData.gear[data.gear_type].item.mods.push(data.gear_mods_data[1].mod_id);
        }

        $('.gear-rarity-checkboxes input', $targetContainer).each(function (i, el) {
            // console.log($(el));
            if ($(el).val() === data.gear_rarity) {
                $(el).trigger('click');
            }
        });
    }

    // ********************************************************************
    // **** GENERAL METHODS
    // ********************************************************************
    function resetSkills($skillsContainer) {
        $('.class-icon').each(function (i, el) {
            $(el).removeClass('selected');
        });
        if ($skillsContainer === 'all') {
            $('.skill-icon').each(function (i, el) {
                $(el).removeClass('selected')
                store.builderData.skills = [];
            });
        } else {
            $('.skill-icon', $skillsContainer).each(function (i, el) {
                $(el).removeClass('selected')
                store.builderData.skills = [];
            });
        }
    }

    function navigateTo(section) {
        switch (section) {
            case 'weapon-calculator':
                break;
            case 'armor-calculator':
                break;
            case 'build-creator':
                break;
        }
    }

    function resetNavTabs() {
        $('.nav-item').each(function (i, el) {
            $(el).removeClass('active');
        });
    }

    function hideGuidesTab() {
        guidesTabOpen = false;
        eventBus.$emit('explorer:TabClose');
        gsap.to($('.explorer-icon').eq(0), {top: '100%', duration: 0.5, ease: "expo.inOut"});
        gsap.to($('.explorer-icon').eq(1), {top: '100%', duration: 0.5, ease: "expo.inOut"});
        gsap.set($('.guides-content'), {
            alpha: 1, delay: 1, onComplete: function () {
                $('.guides-content').removeClass('outlines');
            }
        });

        // $('.footer-container').removeClass('show');

        gsap.to($('.builder-library.explorer'), {
            duration: defaultDuration,
            height: 50,
            top: 'calc(100% - 60px)',
            ease: 'expo.inOut',
            delay: 1,
            onComplete: function () {
                $('.guides-content').removeClass('show');
                $('.guides-btn').removeClass('active');
                gsap.to($('.guides-content'), {duration: defaultDuration, height: 0, ease: 'expo.inOut'});
                gsap.to($('.nav-item'), {duration: defaultDuration, y: 0});
            }
        });

        // gsap.to($('.footer-container'), {
        //     duration: defaultDuration,
        //     height: guidesTabContainerHeight,
        //     top: guidesBtnTop,
        //     ease: 'expo.inOut',
        //     delay: 1,
        //     onComplete: function () {
        //         $('.guides-content').removeClass('show');
        //         $('.guides-btn').removeClass('active');
        //         gsap.to($('.guides-content'), {duration: defaultDuration, height: 0, ease: 'expo.inOut'});
        //         gsap.to($('.nav-item'), {duration: defaultDuration, y: 0});
        //     }
        // });
    }

    function showGuidesTab() {
        // $('.footer-container').addClass('show');
        if (builderLibraryTabOpen) {
            builderLibraryTabOpen = false;
            hideBuilderLibraryTab();
        }
        if (builderLibraryModsTabOpen) {
            hideBuilderModsLibraryTab();
        }
        guidesTabOpen = true;
        $('.guides-btn').addClass('active');
        $('.guides-content').addClass('show');

        gsap.to($('.nav-item'), {duration: defaultDuration, y: 100});
        gsap.to($('.explorer-icon').eq(0), {top: 0, duration: 0.5, ease: "expo.inOut"});
        gsap.to($('.explorer-icon').eq(1), {top: 0, duration: 0.5, ease: "expo.inOut"});

        let topPos = headerBounds - guidesTabContainerHeight - topMenuBottomHeight;

        gsap.to($('.builder-library.explorer'), {
            duration: defaultDuration,
            top: headerBounds - guidesTabContainerHeight - topMenuBottomHeight,
            // height: windowHeight - headerBounds - footerBounds + guidesTabContainerHeight + 1 + topMenuBottomHeight,
            height: 'calc(100% - ' + (topPos + footerBounds) + 'px)',
            ease: 'expo.inOut',
            onComplete: function () {
                // $('.builderlibrary-content', $('.builder-library.explorer')).addClass('show');
                $('.guides-content').addClass('outlines');
            }
        });

        // gsap.to($('.footer-container'), {
        //     duration: defaultDuration,
        //     height: windowHeight - headerBounds - footerBounds + guidesTabContainerHeight + 1 + topMenuBottomHeight,
        //     top: headerBounds - guidesTabContainerHeight - topMenuBottomHeight,
        //     ease: 'expo.inOut'
        // });
        gsap.to($('.guides-content'), {
            duration: defaultDuration,
            // height: windowHeight - guidesTabContainerHeight - footerBounds,
            height: 'calc(100% - ' + (footerBounds - 2) + 'px)',
            ease: 'expo.inOut',
            onComplete: function () {
                eventBus.$emit('explorer:TabOpen');
            }
        });
    }

    function hideBuilderModsLibraryTab() {
        gsap.to($('.builder-library.mods'), {duration: defaultDuration, top: '100%', height: 0, ease: 'expo.inOut'});
        // gsap.to($('.guides-content'), {duration: defaultDuration, height: 0, ease: 'expo.inOut'});
        $('.builderlibrary-content', $('.builder-library.mods')).removeClass('show');
        $('.builderlibrary-btn', $('.builder-library.mods')).removeClass('active');
        builderLibraryModsTabOpen = false;
        gsap.to($('.nav-item'), {duration: defaultDuration, y: 0});
    }

    function showBuilderModsLibraryTab() {
        gsap.to($('.builder-library.mods'), {
            duration: defaultDuration,
            top: headerBounds - guidesTabContainerHeight - topMenuBottomHeight,
            height: windowHeight - headerBounds - footerBounds + guidesTabContainerHeight + 1 + topMenuBottomHeight,
            ease: 'expo.inOut',
            onComplete: function () {
                $('.builderlibrary-content', $('.builder-library.mods')).addClass('show');
            }
        });

        $('.builderlibrary-btn').addClass('active');
        builderLibraryModsTabOpen = true;
        gsap.to($('.nav-item'), {duration: defaultDuration, y: 100});
    }

    function hideBuilderLibraryTab() {
        gsap.to($('.builder-library.armory'), {duration: defaultDuration, top: '100%', height: 0, ease: 'expo.inOut'});
        // gsap.to($('.guides-content'), {duration: defaultDuration, height: 0, ease: 'expo.inOut'});
        $('.builderlibrary-content', $('.builder-library.armory')).removeClass('show');
        $('.builderlibrary-btn', $('.builder-library.armory')).removeClass('active');
        builderLibraryTabOpen = false;
        gsap.to($('.nav-item'), {duration: defaultDuration, y: 0});
    }

    function showBuilderLibraryTab() {
        gsap.to($('.builder-library.armory'), {
            duration: defaultDuration,
            top: headerBounds - guidesTabContainerHeight - topMenuBottomHeight,
            height: windowHeight - headerBounds - footerBounds + guidesTabContainerHeight + 1 + topMenuBottomHeight,
            ease: 'expo.inOut'
        });
        $('.builderlibrary-btn', $('.builder-library.armory')).addClass('active');
        $('.builderlibrary-content', $('.builder-library.armory')).addClass('show');
        builderLibraryTabOpen = true;
        gsap.to($('.nav-item'), {duration: defaultDuration, y: 100});
    }

    function disableDuplicateAttributes(value, select) {
        let $select = $(select);
        let $selectGroup = $select.parent().parent().parent();
        let selectedValue = value;
        let selectedValues = [];
        // get all selected values from all selects in the group
        $('select', $selectGroup).each(function (i, selectElement) {
            selectedValues.push($(selectElement).val());
        });

        // ok now go through other selects and disable this value
        $('select', $selectGroup).each(function (i, selectElement) {
            if ($select.attr('id') === $(selectElement).attr('id')) {
                // console.log('found the active one - skipping');
                //  this is the one we selected, don't worry about it
            } else {
                // now loop through this select's options
                $('option', $(selectElement)).each(function (i, option) {
                    // reset all disabled states
                    $(option).prop('disabled', false);

                    if (selectedValues.includes($(option).prop('value'))) {
                        // if this select's value matches the option value
                        if ($(selectElement).val() === $(option).prop('value')) {
                            // this option is actually selected so don't disable
                        } else {
                            // safe to disable
                            $(option).prop('disabled', true);
                        }
                    }
                });
            }
        })
    }

    function setContentHeights() {
        // console.log('set height on resize');
        windowHeight = $(window).innerHeight();
        guidesTabContainerHeight = $('.guides-tab-container').height();
        headerBounds = $('.header-bounds').height();
        footerBounds = $('.footer-bounds').height();
        segmentHeader = $('.segment-header-container').height();
        innerContentDiff = headerBounds + footerBounds;
        innerContentDiffShort = headerBounds + footerBounds + segmentHeader;
        topNavBottomHeight = $('.header-logo-container.flipped').height();
        topMenuBottomHeight = $('.header-inner-bounds').height();
        guidesBtnTop = windowHeight - footerBounds - topNavBottomHeight;
        gsap.set($('.footer-container'), {top: guidesBtnTop});
        // hideGuidesTab();

        // set the height for segments containers
        $('.segments-container').height(windowHeight - innerContentDiff);
        $('.build-list').height(windowHeight - innerContentDiffShort);
        $('.parallax-background').height(windowHeight - innerContentDiff);
        $('.builder-scroller').height(windowHeight - innerContentDiffShort);
        if (builderLibraryModsTabOpen) {
            $('.builder-library.mods').height(windowHeight - headerBounds - footerBounds + guidesTabContainerHeight + 1 + topMenuBottomHeight);
        }
        if (builderLibraryTabOpen) {
            $('.builder-library.armory').height(windowHeight - headerBounds - footerBounds + guidesTabContainerHeight + 1 + topMenuBottomHeight);
        }

        if (guidesTabOpen) {
            let topPos = headerBounds - guidesTabContainerHeight - topMenuBottomHeight;

            gsap.to($('.builder-library.explorer'), {
                duration: defaultDuration,
                top: headerBounds - guidesTabContainerHeight - topMenuBottomHeight,
                height: 'calc(100% - ' + (topPos + footerBounds) + 'px)',
                ease: 'expo.inOut',
            });
        }
    }

    // HELPER METHODS
    function removeArrElementByValue(arr, value) {
        let index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
    }

    // Collect the value of the 'content' property as a string,
    // stripping the quotation marks
    // we identify with this cleverly what platform/breakpoints we
    // are on
    function detectMediaQuery() {
        return $('#mobile-detect').css('content').replace(/"/g, '');
    }

    function detectMediaBreakpoints() {
        return $('#breakpoint-detect').css('content').replace(/"/g, '');
    }

    function getModImageURL(gearType, filename) {
        if (gearType === 'primary' || gearType === 'secondary' || gearType === 'sidearm') {
            return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/mods/weapon/' + filename + '.png';
        } else {
            return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/mods/armor/' + filename + '.jpg';
        }
        return false;
    }

    function checkHash() {
        let currentHash = window.location.hash.substr(1);
        if (currentHash) {
            // console.log(currentHash);
        } else {
            // console.log('homepage');
        }

    }

    const popupCenter = ({url, title, w, h}) => {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, title,
            `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
        )

        if (window.focus) newWindow.focus();
    }
    window.isAuthenticated = function (popupWindow, userdata) {
        axios
            .get('/is_authenticated')
            .then(function (response) {
                store.user.name = response.data.name;
                store.user.image = response.data.image;
                store.user.status = true;
                $('#builds-library-section').removeClass('inactive');
                gsap.to($('.subsegment-container-carousel'), {
                    duration: defaultDuration,
                    xPercent: 0,
                    ease: 'expo.inOut',
                    onComplete: function () {
                        $('#builds-login-section').addClass('inactive');
                    }
                });
                eventBus.$emit('library:GetUserBuilds', {});
                // console.log(response);
                popupWindow.close();
            })
    }

    function quickSaveBuild() {
        axios
            .post('/api/builds/store', {
                data: store.builderData
            })
            .then(function (response) {
                store.builderData.id = response.data.saved_build_id;
                store.builderData.web_id = response.data.saved_web_id;
            })
    }

    function saveAnonymousBuild() {
        let currentClass = $('i', $('#saveBuildBtn')).attr('class');
        $('i', $('#saveBuildBtn')).removeClass();
        $('i', $('#saveBuildBtn')).addClass('fa-solid fa-cog fa-spin');
        // $('i', $('#saveBuildBtn')).addClass('fa-cog');
        // $('i', $('#saveBuildBtn')).addClass('fa-spin');

        axios
            .post('/api/builds/store', {
                data: store.builderData
            })
            .then(function (response) {
                // console.log(response);
                store.builderData.id = response.data.saved_build_id;
                store.builderData.web_id = response.data.saved_web_id;
                $('i', $('#saveBuildBtn')).removeClass();
                $('i', $('#saveBuildBtn')).attr('class', currentClass);
                window.open(document.location.origin + '/build/' + store.builderData.web_id, '_blank');
                // eventBus.$emit('library:GetUserBuilds', {});
                // if (store.builderData.web_id) {
                //     $('#viewbuild-btn').removeClass('inactive');
                // } else {
                //     $('#viewbuild-btn').addClass('inactive');
                // }
            })
    }

    function saveBuild() {
        let currentClass = $('i', $('#saveBuildBtn')).attr('class');
        $('i', $('#saveBuildBtn')).removeClass();
        $('i', $('#saveBuildBtn')).addClass('fa-solid fa-cog fa-spin');
        // $('i', $('#saveBuildBtn')).addClass('fa-cog');
        // $('i', $('#saveBuildBtn')).addClass('fa-spin');

        axios
            .post('/api/builds/store', {
                data: store.builderData
            })
            .then(function (response) {
                // console.log(response);
                store.builderData.id = response.data.saved_build_id;
                store.builderData.web_id = response.data.saved_web_id;
                // console.log('web_id: ', store.builderData.web_id);
                // console.log('id: ', store.builderData.id);
                $('i', $('#saveBuildBtn')).removeClass();
                $('i', $('#saveBuildBtn')).attr('class', currentClass);
                if (store.builderData.web_id) {
                    $('#viewbuild-btn').removeClass('inactive');
                } else {
                    $('#viewbuild-btn').addClass('inactive');
                }
                eventBus.$emit('library:GetUserBuilds', {});
            })
    }

    function isUserLoggedIn() {
        axios
            .get('/is_authenticated')
            .then(function (response) {
                let logginInfo = response.data;
                if (logginInfo.status) {
                    store.user.status = logginInfo.status;
                    store.user.image = logginInfo.image;
                    store.user.name = logginInfo.name;
                    store.user.provider = logginInfo.provider;
                    $('#builds-login-section').addClass('inactive')
                    // console.log(store.user);
                    eventBus.$emit('library:GetUserBuilds', {});
                } else {
                    $('#builds-library-section').addClass('inactive')
                }
            })
    }

    // ********************************************************************
    // **** CLASS TREE METHODS
    // ********************************************************************
    function showActiveTree(className) {

        // show selected class's active tree
        $('.class-trees-content.' + className).addClass('active');

        // define the jquery objects for the active class on the tree
        // classRootPrefix = '.skill-tree-container.' + className
        // $nodes = $("path, g, rect", $(classRootPrefix + ' #classtree #skill-tree #nodes'));
        // $clickableNodes = $("rect, > g:not(.active-root)", $(classRootPrefix + ' #classtree #skill-tree #nodes'));
        // $classTree = $(classRootPrefix + ' #classtree');
        // $skillTree = $('#skill-tree', $classTree);

        // ***********************************************************
        // define the events on the elements on the class tree
        // ***********************************************************
        $('.class-trees-content.' + selectedClass + ' #resetTreeBtn').off('click').on('click', function (e) {
            resetTree();
        });

        $clickableNodes.off('click').on('click', function (e) {
            if (checkPoints() > 0) {
                let id = $(this).attr('id');
                if ($(this).hasClass('available')) {
                    if ($(this).hasClass('active')) {
                        // nothing
                    } else {
                        // add active class to the clicked node
                        $(this).addClass('active');
                        Tipped.hide($(this));
                        // find all forward connecting nodes for this node
                        let nodeConnections = classTree[0].relationships.filter(x => x.from === id);
                        _.forEach(nodeConnections, function (val) {
                            let $nextNode = $('#' + val.to, $classTree);
                            let $nextConnector = $('#' + val.connector, $classTree);
                            $nextNode.addClass('available');
                            $nextConnector.addClass('available');
                            if ($nextNode.hasClass('active')) {
                                $nextConnector.addClass('active');
                            }
                        })

                        // find all previously connecting nodes for this node
                        let nodeConnectionsTo = classTree[0].relationships.filter(x => x.to === id);
                        _.forEach(nodeConnectionsTo, function (val) {
                            let $fromNode = $('#' + val.from, $classTree);
                            let $fromConnector = $('#' + val.connector, $classTree);
                            // check if the pre-connecting node has been activated
                            // if it has we are safe to mark the connector active
                            if ($fromNode.hasClass('active')) {
                                $fromConnector.addClass('active');
                            }
                        })
                        activeNodesArr.push(id);
                        store.builderData.tree = activeNodesArr;
                        // console.log(activeNodesArr);
                        updatePoints();

                        checkForFullTree(id);

                        if (checkPoints() < 1) {
                            classTreeFilled();
                        }
                    }
                }
            }
        });
        // ***********************************************************
        // end define the events on the elements on the class tree
        // ***********************************************************
        populateNodes();
        checkActiveNodes();

        // console.log($('.active-root', $('.class-trees-content.' + selectedClass)));
        // console.log($('#tooltip-content-0', $('.class-trees-content.' + selectedClass)));
        // class description tooltip
        let $classRoot = $('.active-root', $('.class-trees-content.' + selectedClass));
        Tipped.remove($classRoot);
        Tipped.create($classRoot, {
            inline: selectedClass + '-tooltip-content-0',
            skin: 'light',
            radius: false,
            behavior: 'mouse',
            padding: false,
            position: 'right',
            size: 'large',
        });

        // node tooltips
        $clickableNodes.each(function (i, el) {
            // let tooltipElement = '.class-trees-content.' + selectedClass + ' .skill-tree-container svg #classtree #skill-tree #nodes #' + $(el).attr('id');
            // console.log(tooltipElement);
            Tipped.remove($(el));
            Tipped.create($(el), {
                inline: selectedClass + '-tooltip-content-' + $(el).attr('id'),
                skin: 'light',
                radius: false,
                behavior: 'mouse',
                padding: false,
                position: 'top',
                size: 'large',
                onShow: function (content, element) {
                    // console.log('Showing the tooltip for:', element);
                    let tooltipId = $(element).attr('id');
                    let nodeId = tooltipId.substring(tooltipId.indexOf(selectedClass + "-tooltip-content-"))
                    let $node = $('#' + nodeId, $classTree);
                    let $tooltipTitle = $('.node-tooltip .node-tooltip-title', $(content));
                    if ($node.hasClass('active')) {
                        // console.log($tooltipTitle);
                        $tooltipTitle.addClass('active');
                    } else {
                        $tooltipTitle.removeClass('active');
                    }
                }
            });
        });
    }

    function showActiveTreeRender(className) {
        // console.log('showActiveTreeRender');
        // console.log(className);
        // show selected class's active tree
        $('.class-trees-content.' + className).addClass('active');
        populateNodesRender();
        checkActiveNodesRender();
    }

    function updatePoints() {
        let targetNoPoints = 20;
        let activePoints = activeNodesArr.length - 1;
        let remainingPoints = targetNoPoints - activePoints;
        $('.class-trees-content.' + selectedClass + ' #tree-points span').text(remainingPoints < 10 ? '0' + remainingPoints.toString() : remainingPoints.toString());
    }

    function checkPoints() {
        let targetNoPoints = 20;
        let activePoints = activeNodesArr.length - 1;
        return targetNoPoints - activePoints;
    }

    function classTreeFilled() {
        // console.log('classTreeFilled');
        // console.log(activeNodesArr);
        $('#connectors > *', $classTree).each(function (i, el) {
            if ($(el).hasClass('active')) {
                // it has active assigned to it don't do anything
            } else {
                $(el).removeClass('available');
            }
        });
        $('#nodes > *', $classTree).each(function (i, el) {
            let elId = $(el).attr('id');
            if (!activeNodesArr.includes(elId)) {
                $(el).removeClass('available');
            }
        });
    }

    function resetTree(withActiveUpdate = true) {
        if (withActiveUpdate) {
            $('#connectors > *', $classTree).each(function (i, el) {
                $(el).removeClass('available');
                $(el).removeClass('active');
            });
            $('#nodes > *', $classTree).each(function (i, el) {
                $(el).removeClass('available');
                $(el).removeClass('active');
            });
            $('.class-visual', $classTree).each(function (i, el) {
                $(el).removeClass('active');
                gsap.to($(el), {opacity: 0.5, duration: 0});
                // gsap.to($(el), {opacity: 0.2, duration: 0.3, delay: i * 0.2});
            });

            $('#class-labels', $classTree).each(function (i, el) {
                $(el).removeClass('active');
            });
            $('#class-labels > path', $classTree).removeClass('active');
            activeNodesArr = [];
            store.builderData.tree = [];
            $('#nodes #0', $classTree).addClass('active');
            checkActiveNodes();
            updatePoints();
        } else {
            $('#nodes #0', $classTree).addClass('active');
            checkActiveNodes();
            updatePoints();
        }
    }

    function findNodeRelationship(originNode) {
        for (let i = 0; i < classTree[0].relationships.length; i++) {
            let nodeData = classTree[0].relationships[i];
            if (originNode === nodeData.from) {
                // found matching node
                // highlight available
                let availableNode = nodeData.to;
                let availableConnector = nodeData.connector;
                $('#' + availableNode, $classTree).addClass('available');
                $('#' + availableConnector, $classTree).addClass('available');
            }
        }
    }

    function checkActiveNodes() {
        $nodes.each(function (i, el) {
            if ($(el).hasClass('active')) {
                let elId = $(el).attr('id')
                if (!activeNodesArr.includes(elId)) {
                    activeNodesArr.push(elId)
                }
                store.builderData.tree = activeNodesArr;
                findNodeRelationship($(el).attr('id'));
            }
        })
        if (checkPoints() < 1) {
            classTreeFilled();
        }
    }

    function checkActiveNodesRender() {
        $nodes.each(function (i, el) {
            if ($(el).hasClass('active')) {
                let elId = $(el).attr('id')
                if (!activeNodesArr.includes(elId)) {
                    activeNodesArr.push(elId)
                }
                store.builderData.tree = activeNodesArr;
                findNodeRelationship($(el).attr('id'));
            }
        })
        if (checkPoints() < 1) {
            classTreeFilled();
        }
    }

    function checkForFullTree(nodeId) {
        switch (nodeId) {
            case '28':
                $('.class-visual:nth-child(1)', $classTree).addClass('active');
                $('#class-labels > path:nth-child(1)', $classTree).addClass('active');
                gsap.to($('.class-visual:nth-child(1)', $classTree), {duration: 0.5, ease: 'expo.out', opacity: 1});
                break;
            case '54':
                $('.class-visual:nth-child(2)', $classTree).addClass('active');
                $('#class-labels > path:nth-child(2)', $classTree).addClass('active');
                gsap.to($('.class-visual:nth-child(2)', $classTree), {duration: 0.5, ease: 'expo.out', opacity: 1});
                break;
            case '78':
                $('.class-visual:nth-child(3)', $classTree).addClass('active');
                $('#class-labels > path:nth-child(3)', $classTree).addClass('active');
                gsap.to($('.class-visual:nth-child(3)', $classTree), {duration: 0.5, ease: 'expo.out', opacity: 1});
                break;
        }
    }

    function populateNodes() {
        // resetTree();
        // console.log(activeNodesArr);
        // console.log('populating nodes', activeNodesArr);

        _.forEach(activeNodesArr, function (nodeId) {

            $('#' + nodeId, $classTree).addClass('active');

            checkForFullTree(nodeId);

            // find all forward connecting nodes for this node
            let nodeConnections = classTree[0].relationships.filter(x => x.from === nodeId);
            _.forEach(nodeConnections, function (val) {
                let $nextNode = $('#' + val.to, $classTree);
                let $nextConnector = $('#' + val.connector, $classTree);
                $nextNode.addClass('available');
                $nextConnector.addClass('available');
                if ($nextNode.hasClass('active')) {
                    $nextConnector.addClass('active');
                }
            })
            // find all previously connecting nodes for this node
            let nodeConnectionsTo = classTree[0].relationships.filter(x => x.to === nodeId);
            _.forEach(nodeConnectionsTo, function (val) {
                let $fromNode = $('#' + val.from, $classTree);
                let $fromConnector = $('#' + val.connector, $classTree);
                // check if the pre-connecting node has been activated
                // if it has we are safe to mark the connector active
                if ($fromNode.hasClass('active')) {
                    $fromConnector.addClass('active');
                }
            })
            updatePoints();
        })
        if (checkPoints() < 1) {
            classTreeFilled();
        }
    }

    function populateNodesRender() {
        // resetTree();
        // console.log(activeNodesArr);
        _.forEach(activeNodesArr, function (nodeId) {

            $('#' + nodeId, $classTree).addClass('active');

            checkForFullTree(nodeId);

            // find all forward connecting nodes for this node
            let nodeConnections = classTree[0].relationships.filter(x => x.from === nodeId);

            _.forEach(nodeConnections, function (val) {
                let $nextNode = $('#' + val.to, $classTree);
                let $nextConnector = $('#' + val.connector, $classTree);
                $nextNode.addClass('available');
                $nextConnector.addClass('available');
                if ($nextNode.hasClass('active')) {
                    // console.log('nodeConnectionsTo - active');
                    $nextConnector.addClass('active');
                }
            })
            // find all previously connecting nodes for this node
            let nodeConnectionsTo = classTree[0].relationships.filter(x => x.to === nodeId);
            // console.log(nodeConnectionsTo);
            _.forEach(nodeConnectionsTo, function (val) {
                let $fromNode = $('#' + val.from, $classTree);
                let $fromConnector = $('#' + val.connector, $classTree);
                // check if the pre-connecting node has been activated
                // if it has we are safe to mark the connector active
                if ($fromNode.hasClass('active')) {
                    // console.log('nodeConnectionsTo - active');
                    $fromConnector.addClass('active');
                }
            })
            updatePoints();
        })
        if (checkPoints() < 1) {
            classTreeFilled();
        }
    }

    function resetAllClassTrees() {
        $('.class-trees-content').each(function (i, el) {
            $(el).removeClass('active');
        });
    }

    function populateBuilder() {
        let $classIcon, pos, $skillsContainer;
        if (store.builderData.character) {
            let cachebuster = Math.round(new Date().getTime() / 1000);
            $('.character-module').addClass('active');
            $('img', $('.character-image')).attr('src', store.builderData.character);
        }

        if (store.builderData.class) {
            selectedClass = store.builderData.class;
            $classIcon = $('.class-chooser .class-icon[data-class="' + selectedClass + '"]');
            $classIcon.addClass('selected');
            pos = $classIcon.attr('data-id')
            gsap.to($('.skills-carousel'), {duration: 0.5, xPercent: (-1 * pos) * 100, ease: 'expo.inOut'});
            $skillsContainer = $('#skills-' + selectedClass);
            // loop through skills for active class and populate selections
            $('.skill-icon', $skillsContainer).each(function (i, el) {
                let skillID = $(el).attr('data-id');
                _.forEach(store.builderData.skills, function (val, index) {
                    if (skillID == val) {
                        $(el).addClass('selected');
                    }
                });
            });
            // if we have the class defined set up the objects
            classRootPrefix = '.skill-tree-container.' + selectedClass;
            $nodes = $("path, g, rect", $(classRootPrefix + ' #classtree #skill-tree #nodes'));
            $clickableNodes = $("rect, > g:not(.active-root)", $(classRootPrefix + ' #classtree #skill-tree #nodes'));
            $classTree = $(classRootPrefix + ' #classtree');
            $skillTree = $('#skill-tree', $classTree);

            // assign node points data and skill tree data for the selected class
            switch (selectedClass) {
                case "technomancer":
                    classTree = technomancerTree;
                    break;
                case "pyromancer":
                    classTree = pyromancerTree;
                    break;
                case "trickster":
                    classTree = tricksterTree;
                    break;
                case "devastator":
                    classTree = devastatorTree;
                    break;
            }
            $('#class-tree-btn').removeClass('unavailable');

            activeNodesArr = store.builderData.tree;
            showActiveTree(selectedClass);
        }

        if (store.builderData.character) {
            $('.character-module').addClass('active');
            $('img', $('.character-image')).attr('src', store.builderData.character);
            $('img', $('.character-image')).css('display', 'initial');
        } else {
            $('.character-module').removeClass('active')
            $('img', $('.character-image')).css('display', 'none');
            $('img', $('.character-image')).attr('src', '');
        }

        $('#build-title').val(store.builderData.title);
        $('#build-description').val(store.builderData.description);
        $('#build-twitch').val(store.builderData.twitch);
        $('#build-youtube').val(store.builderData.youtube);
        $('#build-discord').val(store.builderData.discord);

        populateGearItem('headgear');
        populateGearItem('upper');
        populateGearItem('lower');
        populateGearItem('gloves');
        populateGearItem('footgear');
        populateGearItem('primary');
        populateGearItem('secondary');
        populateGearItem('sidearm');

    }

    function populateBuilderRender() {
        selectedClass = store.builderData.class;

        // console.log('populateBuilder class-> ', selectedClass);
        let $classIcon, pos, $skillsContainer;

        // if we have the class defined set up the objects
        classRootPrefix = '.skill-tree-container.' + selectedClass;
        $nodes = $("path, g, rect", $(classRootPrefix + ' #classtree #skill-tree #nodes'));
        $clickableNodes = $("rect, > g:not(.active-root)", $(classRootPrefix + ' #classtree #skill-tree #nodes'));
        $classTree = $(classRootPrefix + ' #classtree');
        $skillTree = $('#skill-tree', $classTree);

        // assign node points data and skill tree data for the selected class
        switch (selectedClass) {
            case "technomancer":
                classTree = technomancerTree;
                break;
            case "pyromancer":
                classTree = pyromancerTree;
                break;
            case "trickster":
                classTree = tricksterTree;
                break;
            case "devastator":
                classTree = devastatorTree;
                break;
        }
        $('#class-tree-btn').removeClass('unavailable');

        activeNodesArr = store.builderData.tree;
        showActiveTreeRender(selectedClass);
    }

    function clearBuilder() {
        resetStoreBuilderData();
        selectedClass = null;
        classRootPrefix = null
        $nodes = null;
        $clickableNodes = null;
        $classTree = null;
        $skillTree = null;
        activeNodesArr = [];
        classTree = null;

        $('#screenshot-upload').val('');
        $('img', $('.character-image')).css('display', 'none');
        $('img', $('.character-image')).attr('src', '');

        resetSkills('all');
        gsap.to($('.skills-carousel'), {duration: 0.5, xPercent: (-1 * 0) * 100, ease: 'expo.inOut'});

        $('#build-title').val(store.builderData.title);
        $('#build-description').val(store.builderData.description);
        $('#build-twitch').val(store.builderData.twitch);
        $('#build-youtube').val(store.builderData.youtube);
        $('#build-discord').val(store.builderData.discord);

        $('#class-tree-btn').addClass('unavailable');
        resetAllClassTrees();

        // reset all gear slots
        resetGearSlot('headgear');
        resetGearSlot('upper');
        resetGearSlot('lower');
        resetGearSlot('gloves');
        resetGearSlot('footgear');
        resetGearSlot('primary');
        resetGearSlot('secondary');
        resetGearSlot('sidearm');
    }

    function resetGearSlot(gearType) {
        let $targetContainer = $('#gear-' + gearType);

        $('.gear-title', $targetContainer).text('Choose ' + capitalizeFirstLetter(gearType));
        $('.gear-icon .content', $targetContainer).css('background-image', 'none');
        $('.gear-icon', $targetContainer).removeClass('populated');
        $('.gear-icon-background', $targetContainer).removeClass('common').removeClass('unusual').removeClass('rare').removeClass('epic').removeClass('legendary');

        $('.gear-attributes-chooser select', $targetContainer).each(function (i, el) {
            $(el).val(0);
        });

        $('.gear-mod-label', $targetContainer).eq(0).text('Choose a mod');
        $('.gear-mod-icon .content', $targetContainer).eq(0).css('background-image', 'none');
        $('.gear-mod-icon', $targetContainer).eq(0).removeClass('populated');

        $('.gear-mod-label', $targetContainer).eq(1).text('Choose a mod');
        $('.gear-mod-icon .content', $targetContainer).eq(1).css('background-image', 'none');
        $('.gear-mod-icon', $targetContainer).eq(1).removeClass('populated');

        $('.btn-check', $targetContainer).each(function (i, el) {
            $(el).prop('checked', false);
        });
    }

    function populateGearItem(gearType) {
        let $targetContainer = $('#gear-' + gearType);

        let importedMods = store.builderData.gear[gearType].mods;

        let gearData = {
            gear_id: store.builderData.gear[gearType].item.id,
            gear_type: gearType,
            gear_mods: importedMods,
            gear_mods_data: null,
            gear_rarity: store.builderData.gear[gearType].item.rarity,
            gear_attr_defaults: store.builderData.gear[gearType].attributes,
            gear_image: store.builderData.gear[gearType].item.image,
            gear_title: store.builderData.gear[gearType].item.name
        }

        // get active modes
        let modsToSend = [];

        _.forEach(importedMods, function (mod, index) {
            // mod is the ID
            let modObj = {};

            _.forEach(mods, function (modItem, modIndex) {
                if (modItem.id === mod) {
                    modObj.mod_id = modItem.id;
                    modObj.mod_type = modItem.type;
                    modObj.mod_gear_type = gearType;
                    modObj.mod_tier = modItem.tier;
                    modObj.mod_image = getModImageURL(gearType, modItem.image);
                    modObj.mod_name = modItem.name;
                }
            })

            modsToSend.push(modObj);
        })

        if (gearData.gear_id) {
            assignSelectedGear(gearData);
        }

        gearData.gear_mods_data = modsToSend;
        if (gearData.gear_mods_data[0].mod_id) {
            let $mod1 = $('.gear-mod-icon', $targetContainer).eq(0)
            gearData.gear_mods_data[0].mod_caller = $mod1;
            assignSelectedMod(gearData.gear_mods_data[0]);
        }

        if (gearData.gear_mods_data[1].mod_id) {
            let $mod2 = $('.gear-mod-icon', $targetContainer).eq(1)
            gearData.gear_mods_data[1].mod_caller = $mod2;
            assignSelectedMod(gearData.gear_mods_data[1]);
        }

        let gearAttrArr = [...gearData.gear_attr_defaults];
        let selectValues = [];

        $('.gear-attributes-chooser select', $targetContainer).each(function (i, el) {
            let optionArr = [];
            // loop through selects for target container
            $('option', $(el)).each(function (j, option_el) {
                if ($(option_el).val()) {
                    optionArr.push(parseInt($(option_el).val()));
                }
            });
            selectValues.push(optionArr);
        });
        _.forEach(selectValues, function (arr, index) {
            let t = gearAttrArr.some(function (arrEl) {
                if (arr.includes(arrEl)) {
                    gearAttrArr.splice(gearAttrArr.indexOf(arrEl), 1);
                    $('.gear-attributes-chooser select', $targetContainer).eq(index).val(arrEl);
                    return true;
                }
                return false;
            });
        });
    }

    function resetStoreBuilderData() {
        store.builderData = {
            title: 'Untitled build',
            id: null,
            web_id: null,
            description: '',
            twitch: '',
            youtube: '',
            discord: '',
            character: '',
            character_screenshot: '',
            class: '',
            skills: [],
            tree: [],
            gear: {
                headgear: {
                    item: {
                        id: null, // piece id
                        type: null, // common, unusual, rare, epic, legendary
                        name: null, // Helmet Of The Acari
                        image: null, // source to the image S3
                        rarity: null, // item rarity
                    },
                    attributes: [0, 0, 0],
                    mods: [0, 0]
                },
                upper: {
                    item: {
                        id: null, // piece id
                        type: null, // common, unusual, rare, epic, legendary
                        name: null, // Helmet Of The Acari
                        image: null, // source to the image S3
                        rarity: null, // item rarity
                    },
                    attributes: [0, 0, 0],
                    mods: [0, 0]
                },
                lower: {
                    item: {
                        id: null, // piece id
                        type: null, // common, unusual, rare, epic, legendary
                        name: null, // Helmet Of The Acari
                        image: null, // source to the image S3
                        rarity: null, // item rarity
                    },
                    attributes: [0, 0, 0],
                    mods: [0, 0]
                },
                gloves: {
                    item: {
                        id: null, // piece id
                        type: null, // common, unusual, rare, epic, legendary
                        name: null, // Helmet Of The Acari
                        image: null, // source to the image S3
                        rarity: null, // item rarity
                    },
                    attributes: [0, 0, 0],
                    mods: [0, 0]
                },
                footgear: {
                    item: {
                        id: null, // piece id
                        type: null, // common, unusual, rare, epic, legendary
                        name: null, // Helmet Of The Acari
                        image: null, // source to the image S3
                        rarity: null, // item rarity
                    },
                    attributes: [0, 0, 0],
                    mods: [0, 0]
                },
                primary: {
                    item: {
                        id: null, // piece id
                        type: null, // common, unusual, rare, epic, legendary
                        name: null, // Helmet Of The Acari
                        image: null, // source to the image S3
                        rarity: null, // item rarity
                    },
                    attributes: [0, 0, 0],
                    mods: [0, 0]
                },
                secondary: {
                    item: {
                        id: null, // piece id
                        type: null, // common, unusual, rare, epic, legendary
                        name: null, // Helmet Of The Acari
                        image: null, // source to the image S3
                        rarity: null, // item rarity
                    },
                    attributes: [0, 0, 0],
                    mods: [0, 0]
                },
                sidearm: {
                    item: {
                        id: null, // piece id
                        type: null, // common, unusual, rare, epic, legendary
                        name: null, // Helmet Of The Acari
                        image: null, // source to the image S3
                        rarity: null, // item rarity
                    },
                    attributes: [0, 0, 0],
                    mods: [0, 0]
                }
            }
        };
    }

    function renderCheckAndAdjust() {
        if ($('.build-render-container').length) {
            let renderBuildData = JSON.parse($('.build-render-container').attr('data-render-json'));
            store.builderData = renderBuildData;
            // console.log(store.builderData);
            populateBuilderRender();
            $('.class-trees').css('display', 'flex');
            $('.class-trees').css('background-color', 'transparent');
            $('.class-trees').css('border-radius', '0');
            $('.classtree-container').css('display', 'flex');
            $('.skill-tree-points-container', $('.classtree-container')).each(function (i, el) {
                $(el).css('display', 'none');
            });
            $('.classtree-close-btn').css('display', 'none');
            $('.skill-tree-container').css('background-image', 'none');
            $('.skill-tree-container').css('padding', '0');
            $('.skill-tree-container svg').css('padding', '0');
            $('.skill-tree-container svg .active-root').css('visibility', 'hidden');
            $('.skill-tree-container svg #c1').attr('d', 'M0 527.162L187.119 420.214');
            $('.skill-tree-container svg #c36').attr('d', 'M0 527.162L239 515');
            $('.skill-tree-container svg #c68').attr('d', 'M0 527.162L189.167 609.414');

            // showActiveTree(selectedClass);
        }
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }


    // CALCULATOR METHODS
    $('#select-weapon-type').on('change', function (e) {
        weaponObject.type = $(this).val();
        // console.log(weaponObject);
    })
    $('#select-weapon-rarity-type').on('change', function (e) {
        weaponObject.rarity = $(this).val();
        // console.log(weaponObject);
    })

    $('#select-weapon-level').on('change', function (e) {
        weaponObject.level = $(this).val();
        // console.log(weaponObject);
    })

    $('#weapon-firepower-value').on('change', function (e) {
        weaponObject.value = $(this).val();
        // console.log(weaponObject);
    })

    $('#select-armor-type').on('change', function (e) {
        armorObject.type = $(this).val();
        // console.log(armorObject);
    })
    $('#select-armor-rarity-type').on('change', function (e) {
        armorObject.rarity = $(this).val();
        // console.log(armorObject);
    })

    $('#select-armor-level').on('change', function (e) {
        armorObject.level = $(this).val();
        // console.log(armorObject);
    })

    $('#armor-value').on('change', function (e) {
        armorObject.value = $(this).val();
        // console.log(armorObject);
    })

    $('#calculate_weapon_btn').click(function (e) {
        const data = calculatorData[weaponObject.type];
        let fp = weaponObject.value;
        _.forEach(data.multipliers, function (val, index) {
            if (val.level > weaponObject.level) {
                fp = Math.round(fp * val.multiplier);
                // fp = fp_with_multiplier;
            }
        })
        let recommended = getRecommendedFirepowerForLevel(weaponObject.level);

        $('.estimate-col.unusual.weapon .init-value').text('~ ' + recommended.unusual);
        $('.estimate-col.rare.weapon .init-value').text('~ ' + recommended.rare);
        $('.estimate-col.epic.weapon .init-value').text('~ ' + recommended.epic);
        $('.estimate-col.legendary.weapon .init-value').text('~ ' + recommended.legendary);

        if (weaponObject.rarity == 'unusual') {
            // requested is unusual show unusual, rare and epic
            let unusual_fp = fp;
            let rare_fp = fp * data.unusual;
            let epic_fp = rare_fp * data.epic;
            $('#weapon_stat_unusual').addClass('show');
            $('#weapon_stat_rare').addClass('show');
            $('#weapon_stat_epic').addClass('show');
            $('#weapon_stat_legendary').removeClass('show');

            $('.result-short', $('#weapon_stat_unusual')).text(shortDisplayFirepower(unusual_fp));
            $('.result-full', $('#weapon_stat_unusual')).text(Math.round(unusual_fp));

            $('.result-short', $('#weapon_stat_rare')).text(shortDisplayFirepower(rare_fp));
            $('.result-full', $('#weapon_stat_rare')).text(Math.round(rare_fp));

            $('.result-short', $('#weapon_stat_epic')).text(shortDisplayFirepower(epic_fp));
            $('.result-full', $('#weapon_stat_epic')).text(Math.round(epic_fp));

            checkWeaponTier(epic_fp)
        }

        if (weaponObject.rarity == 'rare') {
            let rare_fp = fp;
            let epic_fp = fp * data.epic;
            $('#weapon_stat_unusual').removeClass('show');
            $('#weapon_stat_rare').addClass('show');
            $('#weapon_stat_epic').addClass('show');
            $('#weapon_stat_legendary').removeClass('show');

            $('.result-short', $('#weapon_stat_rare')).text(shortDisplayFirepower(fp));
            $('.result-full', $('#weapon_stat_rare')).text(Math.round(fp));

            $('.result-short', $('#weapon_stat_epic')).text(shortDisplayFirepower(epic_fp));
            $('.result-full', $('#weapon_stat_epic')).text(Math.round(epic_fp));

            checkWeaponTier(epic_fp)
        }

        if (weaponObject.rarity == 'epic') {
            let epic_fp = fp;
            $('#weapon_stat_unusual').removeClass('show');
            $('#weapon_stat_rare').removeClass('show');
            $('#weapon_stat_epic').addClass('show');
            $('#weapon_stat_legendary').removeClass('show');

            $('.result-short', $('#weapon_stat_epic')).text(shortDisplayFirepower(epic_fp));
            $('.result-full', $('#weapon_stat_epic')).text(Math.round(epic_fp));

            checkWeaponTier(epic_fp)
        }

        if (weaponObject.rarity == 'legendary') {
            let epic_fp = fp;
            $('#weapon_stat_unusual').removeClass('show');
            $('#weapon_stat_rare').removeClass('show');
            $('#weapon_stat_epic').removeClass('show');
            $('#weapon_stat_legendary').addClass('show');

            $('.result-short', $('#weapon_stat_legendary')).text(shortDisplayFirepower(epic_fp));
            $('.result-full', $('#weapon_stat_legendary')).text(Math.round(epic_fp));

            checkWeaponTier(epic_fp)
        }

        $('.stats-results.weapons').addClass('show');
        $('#weapon_recommended .label-text').text('Recommended Firepower for Level ' + weaponObject.level);
        $('#weapon_recommended').addClass('show');
        $('.weapon_tier_marker').addClass('show');
        getRecommendedBaseline();
    })

    $('#calculate_armor_btn').click(function (e) {
        const data = armorData;
        let fp = armorObject.value;
        _.forEach(data.multipliers, function (val, index) {
            if (val.level > armorObject.level) {
                fp = Math.round(fp * val.multiplier);
                // fp = fp_with_multiplier;
            }
        })
        let recommended = getRecommendedArmorForLevel(armorObject.level);

        $('.estimate-col.unusual.armor .init-value').text('~ ' + recommended.unusual);
        $('.estimate-col.rare.armor .init-value').text('~ ' + recommended.rare);
        $('.estimate-col.epic.armor .init-value').text('~ ' + recommended.epic);
        $('.estimate-col.legendary.armor .init-value').text('~ ' + recommended.legendary);

        if (armorObject.rarity == 'unusual') {
            // requested is unusual show unusual, rare and epic
            let unusual_fp = fp;
            let rare_fp = fp * data.unusual;
            let epic_fp = rare_fp * data.epic;
            $('#armor_stat_unusual').addClass('show');
            $('#armor_stat_rare').addClass('show');
            $('#armor_stat_epic').addClass('show');
            $('#armor_stat_legendary').removeClass('show');

            $('.result-short', $('#armor_stat_unusual')).text(shortDisplayFirepower(unusual_fp));
            $('.result-full', $('#armor_stat_unusual')).text(Math.round(unusual_fp));

            $('.result-short', $('#armor_stat_rare')).text(shortDisplayFirepower(rare_fp));
            $('.result-full', $('#armor_stat_rare')).text(Math.round(rare_fp));

            $('.result-short', $('#armor_stat_epic')).text(shortDisplayFirepower(epic_fp));
            $('.result-full', $('#armor_stat_epic')).text(Math.round(epic_fp));

            checkArmorTier(epic_fp)
        }

        if (armorObject.rarity == 'rare') {
            let rare_fp = fp;
            let epic_fp = fp * data.epic;
            $('#armor_stat_unusual').removeClass('show');
            $('#armor_stat_rare').addClass('show');
            $('#armor_stat_epic').addClass('show');
            $('#armor_stat_legendary').removeClass('show');

            $('.result-short', $('#armor_stat_rare')).text(shortDisplayFirepower(fp));
            $('.result-full', $('#armor_stat_rare')).text(Math.round(fp));

            $('.result-short', $('#armor_stat_epic')).text(shortDisplayFirepower(epic_fp));
            $('.result-full', $('#armor_stat_epic')).text(Math.round(epic_fp));

            checkArmorTier(epic_fp)
        }

        if (armorObject.rarity == 'epic') {
            let epic_fp = fp;
            $('#armor_stat_unusual').removeClass('show');
            $('#armor_stat_rare').removeClass('show');
            $('#armor_stat_epic').addClass('show');
            $('#armor_stat_legendary').removeClass('show');

            $('.result-short', $('#armor_stat_epic')).text(shortDisplayFirepower(epic_fp));
            $('.result-full', $('#armor_stat_epic')).text(Math.round(epic_fp));

            checkArmorTier(epic_fp)
        }

        if (armorObject.rarity == 'legendary') {
            let epic_fp = fp;
            $('#armor_stat_unusual').removeClass('show');
            $('#armor_stat_rare').removeClass('show');
            $('#armor_stat_epic').removeClass('show');
            $('#armor_stat_legendary').addClass('show');

            $('.result-short', $('#armor_stat_legendary')).text(shortDisplayFirepower(epic_fp));
            $('.result-full', $('#armor_stat_legendary')).text(Math.round(epic_fp));

            checkArmorTier(epic_fp)
        }

        $('.stats-results.armor').addClass('show');
        $('#armor_recommended .label-text').text('Recommended Armor Value for Level ' + armorObject.level);
        $('#armor_recommended').addClass('show');
        $('.armor_tier_marker').addClass('show');

    })

    function checkWeaponTier(compareValue) {
        if (compareValue < 85500) {
            //
            $('#tiertype_weapon').text('AVERAGE');
        }

        if (compareValue >= 85500 && compareValue < 90000) {
            $('#tiertype_weapon').text('USABLE');
        }

        if (compareValue >= 90000 && compareValue < 95000) {
            $('#tiertype_weapon').text('EXCELLENT');
        }

        if (compareValue >= 95000) {
            $('#tiertype_weapon').text('GOD TIER');
        }
    }

    function checkArmorTier(compareValue) {
        if (compareValue < 10000) {
            //
            $('#tiertype_armor').text('AVERAGE');
        }

        if (compareValue >= 10000 && compareValue < 11000) {
            $('#tiertype_armor').text('EXCELLENT');
        }

        if (compareValue >= 11000) {
            $('#tiertype_armor').text('GOD TIER');
        }
    }

    function getRecommendedFirepowerForLevel(lvl) {
        // console.log(lvl);
        // console.log(weaponObject.type);
        const data = calculatorData['assault-rifle'];

        // console.log(data);
        let fp = getRecommendedBaseline(weaponObject.type);
        let recommendedValues = {};
        _.forEach(data.multipliers, function (val, index) {
            fp = Math.round(fp * val.multiplier);
            if (val.level == lvl) {
                recommendedValues = {
                    unusual: Math.ceil(fp),
                    rare: Math.ceil(fp * data.unusual),
                    epic: Math.ceil(fp * data.unusual * data.epic),
                    legendary: Math.ceil(fp * data.unusual * data.epic)
                };
                return false;
            }
        })
        return recommendedValues;
    }

    function getRecommendedArmorForLevel(lvl) {
        const data = armorData;
        // console.log(data);
        let fp = 144;
        let recommendedValues = {};
        _.forEach(data.multipliers, function (val, index) {
            fp = Math.round(fp * val.multiplier);
            if (val.level == lvl) {
                recommendedValues = {
                    unusual: Math.ceil(fp),
                    rare: Math.ceil(fp * data.unusual),
                    epic: Math.ceil(fp * data.unusual * data.epic),
                    legendary: Math.ceil(fp * data.unusual * data.epic)
                };
                return false;
            }
        })
        return recommendedValues;
    }

    function shortDisplayFirepower(value) {
        let shortFirepower = value / 1000;
        return (+shortFirepower.toFixed(1)) + 'K';
    }

    function getRecommendedBaseline() {
        const data = calculatorData[weaponObject.type];
        const epicMultiplier = data.epic;
        const rareMultiplier = data.unusual;
        let base = 90000 / epicMultiplier / rareMultiplier;
        const reversedData = [...data.multipliers].reverse();
        _.forEach(reversedData, function (val, index) {
            base = base / val.multiplier;
            // console.log(base);
        });
        return Math.ceil(base);
    }
})

