<template>
    <div class="profile-container">
        <div class="profile-avatar" :style="'background-image: url(' + userData.image +')'"></div>
        <div class="profile-nickname">
            <div class="label">Outrider</div>
            <div class="nickname">{{ userData.name }}</div>
        </div>
        <div @click="logout()" class="profile-logout"><i class="fa-solid fa-arrow-right-from-bracket"></i></div>
    </div>
</template>
<script>

import store from '../store'
import eventBus from '../eventBus'

export default {
    data: function () {
        return {
            userData: {}
        }
    },
    computed: {
        getUserInfo() {
            this.userData = store.user;
            return store.user;
        },
    },
    mounted() {
        // console.log('User profile component mounted.')
        this.userData = this.getUserInfo;
    },
    methods: {
        logout() {
            axios
                .get('/logout')
                .then(function (response) {
                    // console.log(response);
                    eventBus.$emit('user:Logout', {});
                })
        },
        userAvatar() {
            return "background-image: url('" + this.userData.image + "')";
        }
    }
}
</script>

