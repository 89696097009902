import Vue from 'vue';

export default Vue.observable({
    system: {
        device: 'desktop'
    },
    builds: [],
    user: {
        "name": 'Classified',
        "image": 'https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/default-user-profile.jpg',
        "provider": '',
        "status": false
    },
    builderData: {
        "web_id": null,
        "id": null,
        "title": "Untitled build",
        "description": "",
        "twitch": "",
        "youtube": "",
        "discord": "",
        "class": "",
        "character": "",
        "character_screenshot": "",
        "skills": [],
        "tree": [],
        "gear": {
            "headgear": {
                "item": {
                    "id": null,
                    "type": null,
                    "name": null,
                    "image": null,
                    "rarity": null
                },
                "attributes": [
                    0,
                    0,
                    0
                ],
                "mods": [
                    0,
                    0
                ]
            },
            "upper": {
                "item": {
                    "id": null,
                    "type": null,
                    "name": null,
                    "image": null,
                    "rarity": null
                },
                "attributes": [
                    0,
                    0,
                    0
                ],
                "mods": [
                    0,
                    0
                ]
            },
            "lower": {
                "item": {
                    "id": null,
                    "type": null,
                    "name": null,
                    "image": null,
                    "rarity": null
                },
                "attributes": [
                    0,
                    0,
                    0
                ],
                "mods": [
                    0,
                    0
                ]
            },
            "gloves": {
                "item": {
                    "id": null,
                    "type": null,
                    "name": null,
                    "image": null,
                    "rarity": null
                },
                "attributes": [
                    0,
                    0,
                    0
                ],
                "mods": [
                    0,
                    0
                ]
            },
            "footgear": {
                "item": {
                    "id": null,
                    "type": null,
                    "name": null,
                    "image": null,
                    "rarity": null
                },
                "attributes": [
                    0,
                    0,
                    0
                ],
                "mods": [
                    0,
                    0
                ]
            },
            "primary": {
                "item": {
                    "id": null,
                    "type": null,
                    "name": null,
                    "image": null,
                    "rarity": null
                },
                "attributes": [
                    0,
                    0,
                    0
                ],
                "mods": [
                    0,
                    0
                ]
            },
            "secondary": {
                "item": {
                    "id": null,
                    "type": null,
                    "name": null,
                    "image": null,
                    "rarity": null
                },
                "attributes": [
                    0,
                    0,
                    0
                ],
                "mods": [
                    0,
                    0
                ]
            },
            "sidearm": {
                "item": {
                    "id": null,
                    "type": null,
                    "name": null,
                    "image": null,
                    "rarity": null
                },
                "attributes": [
                    0,
                    0,
                    0
                ],
                "mods": [
                    0,
                    0
                ]
            }
        }
    }
});
