<template>
    <div class="d-flex">
        <div class="armory-inputsearch-container">
            <input @keyup="emitTypeValue" v-model="searchTerm" type="text" id="searchTermInput" class="field-input armory-search-input"
                   placeholder="Search...">
            <div @click="clearAndEmmit" class="search-clear-btn" id="cs-btn"><i class="fa-solid fa-octagon-xmark"></i></div>
        </div>
        <div class="armory-filter-mods-container armor">
            <select class="form-select" v-model="rarityFilter">
                <option value="0">All</option>
                <option value="1">Common</option>
                <option value="2">Unusual</option>
                <option value="3">Rare</option>
                <option value="4">Epic</option>
                <option value="5">Legendary</option>
            </select>
        </div>
    </div>

</template>
<script>

import store from '../store'
import eventBus from "../eventBus";

export default {
    data: function () {
        return {
            rarityFilter: 0,
            searchTerm: ''
        }
    },
    computed: {
        //
    },
    mounted() {
        const self = this;
        // console.log('Input Search component mounted.')
        eventBus.$on('armory:ChangeRaritySelect', function (rarityFilter) {
            self.rarityFilter = rarityFilter;
        });
        eventBus.$on('armory:ClearGearSearch', function () {
            // console.log('trigger clear search');
            self.clearAndEmmit();
        });
    },
    watch: {
        rarityFilter() {
            // console.log('changing rarity value on the list');
            // console.log('the value is now: ', this.rarityFilter);
            eventBus.$emit('armory:GearTypeFilter', this.rarityFilter);
            this.clearAndEmmit();
        }
    },
    methods: {
        clearSearch() {
            $('#searchTermInput', this.$el).val('');
            $('#cs-btn', this.$el).removeClass('show');
            this.searchTerm = '';
        },
        clearAndEmmit() {
            $('#searchTermInput', this.$el).val('');
            $('#cs-btn', this.$el).removeClass('show');
            this.searchTerm = '';
            eventBus.$emit('armory:SearchGear', '');
        },
        emitTypeValue() {
            let typedValue = this.searchTerm.toLowerCase();
            if (typedValue.length > 0) {
                $('#cs-btn', this.$el).addClass('show');
            } else {
                $('#cs-btn', this.$el).removeClass('show');
            }
            eventBus.$emit('armory:SearchGear', typedValue);
        }
    }
}
</script>
