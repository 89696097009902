<template>
    <div class="explorer-container">
        <div class="explorer-bg-container"></div>
        <div class="explorer-sections">
            <div class="explorer-content">
                <div class="explorer-section-carousel">
                    <!-- ARMOR SETS -->
                    <armorsets-component segmentId="armorsets"></armorsets-component>
                    <!-- LEGENDARY WEAPONS SETS -->
                    <legendaryweapons-component segmentId="legendaryweapons"></legendaryweapons-component>
                    <!-- PODCAST INFO -->
                    <podcast-component segmentId="voices"></podcast-component>
                </div>
            </div>
        </div>
        <!-- ****************** EXPLORER NAVIGATOR *************************** -->
        <div class="explorer-navigator">
            <div class="explorer-menu-header">
                <div class="explorer-menu-header-left">
                    <div @click="exploreMenuMove('prev')" class="bottom-explorer-btn left"><i class="fa-solid fa-angle-left"></i></div>
                </div>
                <div @click="toggleMenu" class="explorer-menu-tab">
                    <div class="corner right dark explorer">
                        <div class="inner-corner dark"></div>
                    </div>
                    <div class="inner-content-explorer-tab">
                        <div class="explorer-menu-tab-info">
                            <!--                            <div class="headline">Outriders</div>-->
                            <div class="label">{{ sectionTitle }}</div>
                        </div>
                    </div>
                    <div class="corner left explorer">
                        <div class="inner-corner dark"></div>
                    </div>
                </div>
                <div class="explorer-menu-header-right">
                    <div @click="exploreMenuMove('next')" class="bottom-explorer-btn right"><i class="fa-solid fa-angle-right"></i></div>
                </div>
            </div>
            <div class="explorer-menu-content">
                <div class="explore-menu-carousel">
                    <VueSlickCarousel v-bind="settings_menu" ref="exploreMenu" @afterChange="afterExploreMenuSlideChange">
                        <div @click="selectItem($event)" :data-section="'armorsets'" :data-id=0
                             :class="'set-container-menu' + (sectionID == 0 ? ' selected': '')">
                            <div class="explorer-sectional-item">
                                <div class="inside-visual">
                                    <div class="content" :style="getMenuImage('explorer-armorsets.jpg')"></div>
                                    <div class="content sharpen" :style="getMenuImage('explorer-armorsets.jpg')"></div>
                                </div>
                            </div>
                            <div class="explorer-item-label">Armor Sets</div>
                        </div>
                        <div @click="selectItem($event)" :data-section="'weapons'" :data-id=1
                             :class="'set-container-menu' + (sectionID == 1 ? ' selected': '')">
                            <div class="explorer-sectional-item">
                                <div class="inside-visual">
                                    <div class="content" :style="getMenuImage('explorer-legendaryweapons.jpg')"></div>
                                    <div class="content sharpen" :style="getMenuImage('explorer-legendaryweapons.jpg')"></div>
                                </div>
                            </div>
                            <div class="explorer-item-label">Legendary Weapons</div>
                        </div>
                        <!--                        <div @click="selectItem($event)" :data-section="'enemies'" :data-id=2-->
                        <!--                             :class="'set-container-menu' + (sectionID == 2 ? ' selected': '')">-->
                        <!--                            <div class="explorer-sectional-item">-->
                        <!--                                <div class="inside-visual">-->
                        <!--                                    <div class="content" :style="getMenuImage('explorer-enemies.jpg')"></div>-->
                        <!--                                    <div class="content sharpen" :style="getMenuImage('explorer-enemies.jpg')"></div>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <div class="explorer-item-label">Enemies</div>-->
                        <!--                        </div>-->
                        <!--                        <div @click="selectItem($event)" :data-section="'handbook'" :data-id=3-->
                        <!--                             :class="'set-container-menu' + (sectionID == 3 ? ' selected': '')">-->
                        <!--                            <div class="explorer-sectional-item">-->
                        <!--                                <div class="inside-visual">-->
                        <!--                                    <div class="content" :style="getMenuImage('explorer-handbook.jpg')"></div>-->
                        <!--                                    <div class="content sharpen" :style="getMenuImage('explorer-handbook.jpg')"></div>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <div class="explorer-item-label">Outrider Handbook</div>-->
                        <!--                        </div>-->
                        <!--                        <div @click="selectItem($event)" :data-section="'archives'" :data-id=4-->
                        <!--                             :class="'set-container-menu' + (sectionID == 4 ? ' selected': '')">-->
                        <!--                            <div class="explorer-sectional-item">-->
                        <!--                                <div class="inside-visual">-->
                        <!--                                    <div class="content" :style="getMenuImage('explorer-archives.jpg')"></div>-->
                        <!--                                    <div class="content sharpen" :style="getMenuImage('explorer-archives.jpg')"></div>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <div class="explorer-item-label">Outrider Archives</div>-->
                        <!--                        </div>-->
                        <!--                        <div @click="selectItem($event)" :data-section="'expeditions'" :data-id=5-->
                        <!--                             :class="'set-container-menu' + (sectionID == 5 ? ' selected': '')">-->
                        <!--                            <div class="explorer-sectional-item">-->
                        <!--                                <div class="inside-visual">-->
                        <!--                                    <div class="content" :style="getMenuImage('explorer-expeditions.jpg')"></div>-->
                        <!--                                    <div class="content sharpen" :style="getMenuImage('explorer-expeditions.jpg')"></div>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                            <div class="explorer-item-label">Expeditions</div>-->
                        <!--                        </div>-->
                        <div @click="selectItem($event)" :data-section="'voices'" :data-id=2
                             :class="'set-container-menu' + (sectionID == 2 ? ' selected': '')">
                            <div class="explorer-sectional-item">
                                <div class="inside-visual">
                                    <div class="content" :style="getMenuImage('explorer-voicesofenoch.jpg')"></div>
                                    <div class="content sharpen" :style="getMenuImage('explorer-voicesofenoch.jpg')"></div>
                                </div>
                            </div>
                            <div class="explorer-item-label">Voices of Enoch</div>
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
        </div>
        <!-- ****************** END EXPLORER NAVIGATOR *************************** -->
    </div>
</template>
<style>

</style>
<script>

import store from '../store'
import eventBus from "../eventBus";
// import {Carousel, Slide} from 'vue-carousel';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {OverlayScrollbarsComponent} from 'overlayscrollbars-vue';
import setsData from '../sets.json';
import weapons from "../weapons.json";
import gear from "../gear.json";

export default {
    components: {
        VueSlickCarousel,
        // 'overlay-scrollbars': OverlayScrollbarsComponent
        // Carousel,
        // Slide
    },
    directives: {
        // directive definition
    },
    data: function () {
        return {
            sectionID: 0,
            navigationCollapsable: false,
            menuOpen: false,
            collapsableHeight: 0,
            sectionTitle: 'Armor Sets',
            // sectionTitles: ['Armor Sets', 'Legendary Weapons', 'Enemies', 'Outrider Handbook', 'Outrider Archives', 'Expeditions', 'Voices of Enoch'],
            sectionTitles: ['Armor Sets', 'Legendary Weapons', 'Voices of Enoch'],
            settings_menu: {
                "arrows": false,
                "dots": false,
                "infinite": true,
                "speed": 300,
                "focusOnSelect": true,
                "centerMode": true,
                "slidesToShow": 7,
                "initialSlide": 0,
                "slidesToScroll": 1,
                "cssEase": "ease-in-out",
                responsive: [
                    {
                        "breakpoint": 1400,
                        "settings": {
                            "slidesToShow": 7,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 1200,
                        "settings": {
                            "slidesToShow": 7,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 1024,
                        "settings": {
                            "slidesToShow": 7,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 992,
                        "settings": {
                            "slidesToShow": 7,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 960,
                        "settings": {
                            "slidesToShow": 7,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 800,
                        "settings": {
                            "slidesToShow": 4,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 768,
                        "settings": {
                            "slidesToShow": 4,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 720,
                        "settings": {
                            "slidesToShow": 4,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 640,
                        "settings": {
                            "slidesToShow": 4,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 600,
                        "settings": {
                            "slidesToShow": 4,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 576,
                        "settings": {
                            "slidesToShow": 3,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 480,
                        "settings": {
                            "slidesToShow": 3,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 414,
                        "settings": {
                            "slidesToShow": 3,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                    {
                        "breakpoint": 375,
                        "settings": {
                            "slidesToShow": 2,
                            "centerMode": true,
                            "slidesToScroll": 1,
                            "focusOnSelect": true,
                        }
                    },
                ]
            }
        }
    },
    computed: {},
    watch: {},
    created() {

    },
    mounted() {
        const _this = this;
        // console.log('Explorer component mounted.')
        this.sectionID = 0;

        window.addEventListener("resize", this.windowResizeHandler);

        eventBus.$on('explorer:TabOpen', function () {
            $('.explorer-container').addClass('active');
            $('.explorer-sectionals').addClass('active');
            $('.explorer-bg-container').addClass('active');
            gsap.to($('.explorer-bg-container'), {
                alpha: 1, ease: "expo.out", duration: 0.3, onComplete: function () {
                    gsap.to($('.segment-header-container'), {alpha: 1, ease: "expo.Out", duration: 0.3})
                    gsap.to($('.explorer-sectionals'), {alpha: 1, ease: "expo.Out", delay: 0.3, duration: 0.3});
                }
            });
            _this.windowResizeHandler();
        })
        eventBus.$on('explorer:TabClose', function () {
            gsap.to($('.explorer-bg-container'), {
                alpha: 1, ease: "expo.Out", duration: 0.5, onComplete: function () {
                    $('.explorer-bg-container').removeClass('active');
                }
            })
            gsap.to($('.segment-header-container.explorer'), {alpha: 0, ease: "expo.Out", duration: 0.3})
            gsap.to($('.explorer-sectionals'), {
                alpha: 0, ease: "expo.Out", delay: 0.3, duration: 0.3, onComplete: function () {
                    $('.explorer-sectionals').removeClass('active');
                }
            })
            gsap.to($('.explorer-navigator'), {
                bottom: _this.collapsableHeight,
                // bottom: 0,
                ease: "expo.out",
                delay: 0.6,
                duration: 0.3,
                onComplete: function () {
                    $('.explorer-container').removeClass('active');
                }
            });
        })

        $(document).on('click', function (e) {
            let $explorerMenu = $('.explorer-navigator');
            if (_this.navigationCollapsable) {
                if (!(event.target == $explorerMenu[0] || $explorerMenu[0].contains(event.target))) {
                    if (_this.menuOpen) {
                        gsap.to($('.explorer-navigator'), {bottom: _this.collapsableHeight, ease: "expo.out", duration: 0.3});
                        _this.menuOpen = false;
                    }
                }
            }
        })
    },
    methods: {
        windowResizeHandler() {
            const _this = this;
            _this.collapsableHeight = -1 * ($('.explorer-navigator').height() - $('.explorer-menu-header', $('.explorer-navigator')).height() - 5);
            _this.navigationCollapsable = false;

            if ($(document).innerWidth() <= 375 && $(document).innerHeight() < 740) {
                _this.navigationCollapsable = true;
                _this.resetExploreCollapsable();
            }

            if ($(document).innerWidth() > 375 && $(document).innerWidth() <= 480 && $(document).innerHeight() < 736) {
                _this.navigationCollapsable = true;
                _this.resetExploreCollapsable();
            }

            if ($(document).innerWidth() > 480 && $(document).innerWidth() <= 576 && $(document).innerHeight() < 740) {
                _this.navigationCollapsable = true;
                _this.resetExploreCollapsable();
            }

            if ($(document).innerHeight() < 710) {
                _this.navigationCollapsable = true;
                _this.resetExploreCollapsable();
            }

            if ($(document).innerHeight() <= 899 && $(document).innerWidth() >= 1000) {
                _this.navigationCollapsable = true;
                _this.resetExploreCollapsable();
            }

            // if ($(document).innerHeight() < 900 && $(document).innerWidth() > 1200) {
            //     _this.navigationCollapsable = true;
            //     _this.resetExploreCollapsable();
            // }

            if (_this.navigationCollapsable) {
                _this.menuOpen = false;
                _this.resetExploreCollapsable();
            } else {
                _this.menuOpen = true;
                _this.expandExplorerMenu();
            }
        },
        expandExplorerMenu() {
            $('.explorer-figure').css('height', 'calc(100% - 5px)');
            gsap.to($('.explorer-navigator'), {
                bottom: 0,
                ease: "expo.out",
                duration: 0.3,
                onComplete: function () {
                    //
                }
            });
        },
        collapseExplorerMenu(diffHeight = 45) {
            const _this = this;
            $('.explorer-figure').css('height', 'calc(100% - ' + diffHeight + 'px)');
            gsap.to($('.explorer-navigator'), {
                bottom: _this.collapsableHeight,
                ease: "expo.out",
                duration: 0.3,
                onComplete: function () {
                    //
                }
            });
        },
        resetExploreCollapsable(diffHeight = 45) {
            const _this = this;
            $('.explorer-figure').css('height', 'calc(100% - ' + diffHeight + 'px)');
            gsap.to($('.explorer-navigator'), {
                bottom: _this.collapsableHeight,
                ease: "expo.out",
                duration: 0.3,
                delay: 1
            });
        },
        hasClass(el, className) {
            return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
        },
        addClass(el, className) {
            if (el.classList) el.classList.add(className);
            else if (!this.hasClass(el, className)) el.className += ' ' + className;
        },
        removeClass(el, className) {
            if (el.classList) el.classList.remove(className);
            else el.className = el.className.replace(new RegExp('\\b' + className + '\\b', 'g'), '');
        },
        toggleMenu() {
            if (this.navigationCollapsable) {
                if (!this.menuOpen) {
                    gsap.to($('.explorer-navigator'), {bottom: 0, ease: 'expo.inOut', duration: 0.3});
                    this.menuOpen = true;
                } else {
                    gsap.to($('.explorer-navigator'), {bottom: this.collapsableHeight, ease: "expo.out", duration: 0.3});
                    this.menuOpen = false;
                }
            }
        },
        afterExploreMenuSlideChange(slideIndex) {
            this.sectionID = slideIndex;
            this.sectionTitle = this.sectionTitles[slideIndex];
            gsap.to($('.explorer-section-carousel'), {
                xPercent: -1 * slideIndex * 100, duration: defaultDuration, ease: 'expo.inOut', onComplete: function () {
                    let sectionName = 'explorer-bg-container active ' + $('.set-container-menu').eq(slideIndex).attr('data-section');
                    let $el = $('.explorer-bg-container');
                    $el.removeClass();
                    $el.addClass(sectionName);
                }
            })
        },
        selectItem(e) {
            this.toggleMenu();
        },
        exploreMenuMove(direction) {
            if (direction === 'next') {
                this.$refs['exploreMenu'].next();
            }
            if (direction === 'prev') {
                this.$refs['exploreMenu'].prev();
            }
            return false;
        },
        closePanel() {
            eventBus.$emit('explorer:ClosePanel');
        },
        getMenuImage(filename) {
            return 'background-image: url("https://outriders-outpost.s3.us-west-1.amazonaws.com/explorer/' + filename + '")';
        },
    }
}
</script>
