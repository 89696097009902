<template>
    <div class="build-library">
        <div v-if="builds.length <= 0" class="notice-group">
            <div class="title">No Builds Available</div>
            <div class="description">It looks like you don't have any builds in your library Outrider. Why don't you
                make one and share it with the
                world.
            </div>
        </div>
        <div v-else class="build-item" v-for="build in builds" :data-build-id="build.build_id">
            <div class="build-item-content" @click="openBuild(build)">
                <div class="build-item-visual"><img :src="getBuildImage(build.builder_data.class)"/>
                </div>
                <div class="build-item-info">
                    <div class="build-item-title">{{ build.builder_data.title ? build.builder_data.title : 'Untitled Build' }}</div>
                    <div class="build-item-desc">
                        {{ build.builder_data.description ? truncateDescription(build.builder_data.description) : 'No description available...' }}
                    </div>
                </div>
            </div>
            <div class="build-controls">
                <div @click="duplicateBuild(build.id)" class="build-control-duplicate"><i class="fa-solid fa-clone"></i></div>
                <div @click="deleteBuild(build.id)" class="build-control-delete"><i class="fa-solid fa-trash-can"></i></div>
            </div>
        </div>
    </div>
</template>
<script>

import store from '../store'
import eventBus from "../eventBus";

export default {
    data: function () {
        return {
            builds: [],
        }
    },
    computed: {
        // getBuilds() {
        //     this.builds = store.builds;
        //     return store.builds;
        // },
        checkBuildCount() {
            if (this.builds > 0) {
                return false;
            } else {
                return true;
            }
        }
    },
    mounted() {
        const _this = this;
        // console.log('Build library component mounted.')
        // this.initBuilds();
        eventBus.$on('library:GetUserBuilds', function (data) {
            _this.getBuildsForUser();
        });
    },
    methods: {
        getBuildImage(buildClass) {
            if (buildClass) {
                return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/' + 'builds-' + buildClass + '.png';
                // asset('images/builds-' + build.builder_data.class + '.png')
            } else {
                return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/assets/' + 'builds-missing.png';
            }
        },
        deleteBuild(id) {
            axios
                .post('/api/build/' + id + '/delete', {})
                .then(function (response) {
                    // console.log(response);
                    eventBus.$emit('library:GetUserBuilds', {});
                })
        },
        duplicateBuild(id) {
            axios
                .post('/api/build/' + id + '/duplicate', {})
                .then(function (response) {
                    // console.log(response);
                    eventBus.$emit('library:GetUserBuilds', {});
                })
        },
        openBuild(build) {
            store.builderData = [];
            eventBus.$emit('builder:BuildDetails', build);
        },
        truncateDescription(description) {
            if (store.system.device === 'mobile') {
                return description.substring(0, 100) + '...'
            } else {
                if (description.length > 150) {
                    return description.substring(0, 150) + '...'
                }
                return description;
            }
        },
        getBuildsForUser() {
            const self = this;

            axios
                .get('/api/builds/get')
                .then(function (response) {
                    // console.log('retreived user builds', response.data.builds);
                    // console.log(response.data.builds);
                    _.forEach(response.data.builds, function (el) {
                        el.builder_data = JSON.parse(el.builder_data);
                        el.builder_data.id = el.id;
                        el.builder_data.web_id = el.build_id;
                    });
                    // console.log('getBuildsForUser: ', self.builds);
                    self.builds = response.data.builds;
                    // console.log(self.builds);
                })

        },
        initBuilds() {
            axios
                .get('js/builds.json')
                .then(function (response) {
                    store.builds = response.data.builds;
                })
        }
    }
}
</script>
