<template>
    <div class="armory-content">
        <div v-for="gearItem in gearData" @click="selectGearItem(gearItem)" class="armory-item">
            <div class="item-visual">
                <div :class="'item-visual-bg '+gearItem.rarity"></div>
                <div class="item-visual-bg hovershadow"></div>
                <div class="content">
                    <div :class="'visual' + isSizeAdjusted(gearItem)"
                         :style="getImage(gearItem)"></div>
                    <div :class="'visual' + isSizeAdjusted(gearItem) + ' sharpen'"
                         :style="getImage(gearItem)"></div>
                </div>
            </div>
            <div class="item-title">{{ gearItem.title }}</div>
        </div>
    </div>
</template>
<script>

import store from '../store'
import eventBus from "../eventBus";
import gear from '../gear.json';
import weapons from '../weapons.json';
import weaponTypes from '../weapon-types.json';
import weaponVariants from '../variants.json';
import mods from "../mods.json";

export default {
    data: function () {
        return {
            builds: this.getData,
            caller: null,
            gearData: null,
            gearType: '',
            selectedClass: ''
        }
    },
    computed: {
        getBuilds() {
            this.builds = store.builds;
            return store.builds;
        },
    },
    mounted() {
        // console.log('Builder Armory component mounted.')
        let _this = this;
        eventBus.$on('armory:SearchGear', function (value) {
            $('.armory-item', _this.$el).filter(function () {
                let itemTitle = $('.item-title', $(this)).text().toLowerCase();
                $(this).toggle(itemTitle.indexOf(value) > -1);
            });
        })

        eventBus.$on('armory:GearTypeFilter', function (gearRarity) {
            // console.log('gearType: ', _this.gearType);
            _this.gearData = _this.sortData(gearRarity);
        });

        // listen to click initiator for this specific mod slot on the builder
        // it allows us to identify which mod to fill with data from the select mod
        eventBus.$on('armory:GearInitiator', function (caller) {
            _this.caller = caller.$element;
            _this.gearType = caller.gearType;
            _this.selectedClass = caller.class;
            eventBus.$emit('armory:GearTypeFilter', '0');
            eventBus.$emit('armory:ChangeRaritySelect', '0');
            eventBus.$emit('armory:ClearGearSearch', '');
        })
    },
    methods: {
        sortData(gearRarity) {
            let dt, ds, dn = [];
            let activeData = null;
            if (this.gearType === 'primary' || this.gearType === 'secondary' || this.gearType === 'sidearm') {
                // do weapon related stuff
                activeData = weapons;
            } else {
                // do armor related stuff
                activeData = gear;
            }

            if (parseInt(gearRarity) === 0) {
                if (this.gearType === 'primary' || this.gearType === 'secondary') {
                    dt = activeData.filter(x => x.group !== 'pistol' && x.group !== 'revolver')
                } else if (this.gearType === 'sidearm') {
                    dt = activeData.filter(x => x.group === 'pistol' || x.group === 'revolver')
                } else {
                    dt = activeData.filter(x => x.group === this.gearType)
                }
                dn = dt.sort((a, b) => a.rarity.localeCompare(b.rarity) || a.title.localeCompare(b.title) || a.group.localeCompare(b.group));
            } else {
                let rarityString = '';
                switch (parseInt(gearRarity)) {
                    case 1:
                        rarityString = 'common';
                        break;
                    case 2:
                        rarityString = 'unusual';
                        break;
                    case 3:
                        rarityString = 'rare';
                        break;
                    case 4:
                        rarityString = 'epic';
                        break;
                    case 5:
                        rarityString = 'legendary';
                        break;
                }

                if (this.gearType === 'primary' || this.gearType === 'secondary') {
                    dt = activeData.filter(x => x.group !== 'pistol' && x.group !== 'revolver');
                    let ds = dt.filter(x => x.rarity === rarityString);
                    dt = ds;
                } else if (this.gearType === 'sidearm') {
                    dt = activeData.filter(x => x.group === 'pistol' || x.group === 'revolver');
                    let ds = dt.filter(x => x.rarity === rarityString);
                    dt = ds;
                } else {
                    dt = activeData.filter(x => x.group === this.gearType && x.rarity === rarityString)
                }

                dn = dt.sort((a, b) => a.rarity.localeCompare(b.rarity) || a.title.localeCompare(b.title));
            }
            return dn;
        },
        isSizeAdjusted(gearItem) {
            // if (gearItem.group === 'gloves' || gearItem.group === 'footgear' ||
            //     gearItem.title === "Lucky Ushanka" ||
            //     gearItem.title === "Sergio's Beret" ||
            //     gearItem.title === "Earthborn Renegade's Mask"
            // ) {
            //     return ' breathe';
            // } else {
            //     return '';
            // }
            return '';
        },
        selectGearItem(gearItem) {
            let self = this;
            //imgURL, attributes = [], rarity
            let _gear_type = gearItem.group;
            if (this.gearType === 'primary' || this.gearType === 'secondary' || this.gearType === 'sidearm') {
                _gear_type = this.gearType;
            }
            // get active modes
            let modsToSend = [];

            _.forEach(gearItem.mods, function (mod, index) {
                // mod is the ID
                let modObj = {};

                _.forEach(mods, function (modItem, modIndex) {
                    if (modItem.id === mod) {
                        modObj.mod_id = modItem.id;
                        modObj.mod_type = modItem.type;
                        modObj.mod_gear_type = self.gearType;
                        modObj.mod_tier = modItem.tier;
                        modObj.mod_image = self.getModImageURL(modItem.image);
                        modObj.mod_name = modItem.name;
                    }
                })

                modsToSend.push(modObj);
            })

            // console.log(modsToSend);
            // mod_caller: self.caller,
            // modObj.mod_id: modDataItem.id,
            // modObj.mod_type: modDataItem.type,
            // modObj.mod_tier: modDataItem.tier,
            // modObj.mod_image: self.getImageURL(modDataItem.image),
            // modObj.mod_name: modDataItem.name,

            eventBus.$emit('armory:SelectGear', {
                gear_id: gearItem.id,
                gear_type: _gear_type,
                gear_mods: gearItem.mods,
                gear_mods_data: modsToSend,
                gear_setbonus: gearItem.setbonus,
                gear_rarity: gearItem.rarity,
                gear_attr_defaults: gearItem.attributes,
                gear_image: this.getImageURL(gearItem),
                gear_title: gearItem.title,
            });
        },
        getModImageURL(filename) {
            if (this.gearType === 'primary' || this.gearType === 'secondary' || this.gearType === 'sidearm') {
                return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/mods/weapon/' + filename + '.png';
            } else {
                return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/mods/armor/' + filename + '.jpg';
            }
            return false;
        },
        getImageURL(gearItem) {
            // create the url to the appropriate asset for the gear image
            let rarity = gearItem.rarity; // common, rare, unusual, legendary etc
            let group = gearItem.group; // footgear, upper, headgear etc
            let filename = gearItem.filename;
            if (this.gearType === 'primary' || this.gearType === 'secondary' || this.gearType === 'sidearm') {
                return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/weapons/' + group + '/' + rarity + '/' + filename;
            } else {
                return 'https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/armor/' + group + '/' + rarity + '/' + filename;
            }
        },
        getImage(gearItem) {
            // create the url to the appropriate asset for the gear image
            let rarity = gearItem.rarity; // common, rare, unusual, legendary etc
            let group = gearItem.group; // footgear, upper, headgear etc
            let filename = gearItem.filename;
            if (this.gearType === 'primary' || this.gearType === 'secondary' || this.gearType === 'sidearm') {
                return 'background-image: url("https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/weapons/' + group + '/' + rarity + '/' + filename + '")';
            } else {
                return 'background-image: url("https://outriders-outpost.s3.us-west-1.amazonaws.com/armory/armor/' + group + '/' + rarity + '/' + filename + '")';
            }
        }
    }
}
</script>
